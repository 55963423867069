import { Document } from '@contentful/rich-text-types';
import { ContentMedia } from './content-media.model';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class LandingSection implements Deserializable<JsonObject, LandingSection> {
  constructor(
    public title?: string,
    public rawBody?: Document,
    public media?: ContentMedia,
    public buttonText?: string,
    public contentId?: string
  ) {}

  deserialize(input: JsonObject): LandingSection {
    if (input == null) {
      return null;
    }

    this.contentId = input['contentId'] as string;
    this.title = input['title'] as string;
    this.rawBody = (input['body'] || input['rawBody']) as Document;
    this.media = new ContentMedia().deserialize(input['mediaWrapper'] || input['media']);
    this.buttonText = input['buttonText'] as string;

    return this;
  }
}
