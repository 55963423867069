@if (cardBloc.detailViewCard$ | async; as cardData) {
  @if (cardData?.detailViewClicked) {
    <kui-modal class="detail-card" [isOpen]="cardData?.detailViewClicked" (isOpenChange)="closeDetailModal(cardData)">
      <kp-detail-card-view
        [card]="cardData?.card"
        [screen]="cardData?.screen"
        [quest]="cardData?.quest"
        [questItem]="cardData?.questItem"
        [requestType]="cardData?.requestType"
        (modalClose)="closeDetailModal(cardData, $event)"></kp-detail-card-view>
    </kui-modal>
  }
}

@if (localResourcesCardBloc.detailViewCard$ | async; as cardData) {
  @if (cardData?.detailViewClicked) {
    <kui-modal
      class="detail-card"
      [isOpen]="cardData?.detailViewClicked"
      (isOpenChange)="closeDetailModal(cardData)"
      [reverseClose]="true">
      <kp-local-resources-card-detail-view
        [card]="cardData?.card"
        [source]="cardData?.source"
        [screen]="cardData.screen"
        [zipCode]="cardData.zip"
        (modalClose)="closeDetailModal(cardData)"></kp-local-resources-card-detail-view>
    </kui-modal>
  }
}

@if (firstCardModalOpen) {
  <kui-modal [isOpen]="firstCardModalOpen">
    <kp-first-card-added [cardType]="cardType" (closeClicked)="closeFirstCardModal()"></kp-first-card-added>
  </kui-modal>
}

@if (firstCompletedReflection) {
  <kui-modal [isOpen]="firstCompletedReflection" (isOpenChange)="closeFirstReflectionCompleteModalIfOpen()">
    <kp-first-reflection-completed
      (closeClicked)="closeFirstReflectionCompleteModalIfOpen()"></kp-first-reflection-completed>
  </kui-modal>
}

@if (socialChallengeBloc.showCelebrationModal$ | async; as showCelebrationModal) {
  <kui-modal [isOpen]="showCelebrationModal" (isOpenChange)="socialChallengeBloc.closeCelebrationModal()">
    <kf-sc-modal-social-challenge-celebrate></kf-sc-modal-social-challenge-celebrate>
  </kui-modal>
}

@if ({ info: (globalElementsService.currentPageInfo$ | async) }; as page) {
  <kui-a11y-live-announce
    [announcement]="
      'Navigated to new page, ' + (page.info?.meta?.focusTitle || page.info?.pageName || '')
    "></kui-a11y-live-announce>
}
<!-- using aria-hidden so this is not selected via keyboard or screen reader navigation,
but tabindex=-1 so it can be programmatically focused -->
<span id="browser-nav-focus" class="visually-hidden" role="presentation" aria-hidden="true" tabindex="-1"></span>
<a class="skip-to-content-link" [href]="skipLinkPath">{{ 'Skip to Main Content' | translate | async }}</a>

@if (pastReflectionsBloc.pastReflectionViewClicked$ | async; as pastReflectionViewClicked) {
  @if (pastReflectionViewClicked) {
    <kui-modal
      [isOpen]="pastReflectionViewClicked"
      (isOpenChange)="closeDetailModal()"
      labelValue=""
      describedById="pastReflectionsTitle date">
      <kp-reflection-history (closeClicked)="closeDetailModal()"></kp-reflection-history>
    </kui-modal>
  }
}

@if ((versionBloc.appVersionMeetsMinimum$ | async) === false; as failsMinimum) {
  @if (failsMinimum) {
    <kui-modal [isOpen]="failsMinimum" [canDismiss]="false" [contentFlex]="true" labelValue="" describedById="">
      <kui-version-expired
        [product]="environmentVariablesService.product"
        [productName]="productName"></kui-version-expired>
    </kui-modal>
  }
}

@if (globalElementsService.showAccountCreationBanner$ | async) {
  <kp-account-creation-banner></kp-account-creation-banner>
}

@if (globalElementsService.showHeader$ | async) {
  <kp-site-header
    [navActive]="navActive"
    (navBtnClick)="navBtnClicked()"
    [quickActionsOpen]="quickActionsEnabled"
    (quickActionsToggled)="quickActionsToggled($event)"></kp-site-header>
}
@if (globalElementsService.showBasicLogoHeader$ | async) {
  <kp-site-basic-logo-header
    class="hidden-print"
    [ngClass]="{ banner: globalElementsService.showAccountCreationBanner$ | async }"></kp-site-basic-logo-header>
}
<section
  class="main"
  [ngClass]="{
    'no-chrome':
      (globalElementsService.showChrome$ | async) === false &&
      (globalElementsService.showBasicLogoHeader$ | async) === false,
    'no-header':
      (globalElementsService.showHeader$ | async) === false &&
      (globalElementsService.showBasicLogoHeader$ | async) === false,
    'no-nav': (globalElementsService.showNav$ | async) === false,
    'overlap-header': (globalElementsService.showBasicLogoHeader$ | async) === true
  }">
  @if (quickActionsEnabled) {
    <div class="overlay" role="presentation" (click)="closeOverlay()" (keyup.escape)="closeOverlay()"></div>
  }
  @if ((globalElementsService.showChrome$ | async) && (globalElementsService.showNav$ | async)) {
    <kp-site-nav class="hidden-print" [navActive]="navActive" (sidebarClick)="sidebarClicked($event)"></kp-site-nav>
  }
  <main class="content" id="main">
    <!-- using aria-hidden so this is not selected via keyboard or screen reader navigation,
    but tabindex=-1 so it can be programmatically focused -->
    <span id="nav-focus" class="visually-hidden" role="presentation" aria-hidden="true" tabindex="-1"></span>
    <router-outlet></router-outlet>
    @if (
      {
        showPhotoModal: imageBloc.showPhotoModal$ | async,
        photoModalDetails: imageBloc.photoModalDetails$ | async
      };
      as photoModalData
    ) {
      @if (photoModalData.showPhotoModal) {
        <kui-modal [isOpen]="photoModalData.showPhotoModal" (isOpenChange)="imageBloc.closePhotoModal()" type="photo">
          <kp-photo-carousel
            [selectedImageId]="photoModalData.photoModalDetails.selectedImageId"
            [initialImageType]="
              photoModalData.photoModalDetails.image ? photoModalData.photoModalDetails.currentImageType : null
            "
            [allImages]="(imageBloc.imageStatus$(photoModalData.photoModalDetails.carouselImagesType) | async)?.data"
            (closeModal)="imageBloc.closePhotoModal()"></kp-photo-carousel>
        </kui-modal>
      }
    }
  </main>
  @if (globalElementsService.showLiveSupport$ | async) {
    <kp-live-support class="hidden-print"></kp-live-support>
  }
</section>
@if ((globalElementsService.showChrome$ | async) && (globalElementsService.showHeader$ | async)) {
  <kp-site-footer
    [navActive]="navActive"
    (navBtnClick)="navBtnClicked()"
    [quickActionsOpen]="quickActionsEnabled"
    (quickActionsToggled)="quickActionsToggled($event)"></kp-site-footer>
}
<kui-version-tag [hidden]="true"></kui-version-tag>
