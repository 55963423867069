import { AfterViewInit, Directive, ElementRef, Input, Renderer2, ViewContainerRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SharedConstants } from '@kservice';
import { TranslatePipe } from '../../translate.pipe';
import { TranslationBloc } from '../../translation.bloc';

@Directive({
  selector: '[kfLocTranslate]',
  standalone: true,
  providers: [TranslatePipe],
})
export class TranslateDirective implements AfterViewInit {
  private _currentLanguage = SharedConstants.DEFAULT_LANGUAGE;

  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private _renderer: Renderer2,
    private _translatePipe: TranslatePipe,
    private _translationBloc: TranslationBloc,
    private _viewContainerRef: ViewContainerRef
  ) {
    this._translationBloc.currentLanguage$?.pipe(takeUntilDestroyed()).subscribe((currentLanguage) => {
      this._currentLanguage = currentLanguage;
    });
  }

  // defaults to "html" because that is the more likely use case for the Directive
  @Input() format: 'text' | 'html' = 'html';

  ngAfterViewInit() {
    // Don't do anything if the language code is English
    if ([SharedConstants.DEFAULT_LANGUAGE, 'en-US'].includes(this._currentLanguage)) {
      return;
    }
    const elementMethod = this.format === 'text' ? 'innerText' : 'innerHTML';
    const savedComponentText = this.elementRef.nativeElement.getAttribute('data-en');
    const componentText = savedComponentText ?? this.elementRef.nativeElement[elementMethod];
    this.elementRef.nativeElement.setAttribute('data-en', componentText);
    // Using pipe so all translation logic follows the same path
    this._translatePipe.transform(componentText).subscribe((translatedText) => {
      this._viewContainerRef?.clear();
      this._renderer.setProperty(this.elementRef.nativeElement, elementMethod, translatedText);
    });
  }
}
