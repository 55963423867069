import { Injectable } from '@angular/core';
import { TagApi } from '@kapi';
import { DataStoreService } from '@kservice';
import { DataStatus, Status, StatusMessage, Tag, TagKey } from '@ktypes/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, skipWhile, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TagBloc {
  constructor(
    private _dataStoreService: DataStoreService,
    private _tagApi: TagApi
  ) {}

  private _userTags = new BehaviorSubject<DataStatus<Tag[]>>(null);

  get userTags$(): Observable<Tag[]> {
    return this._userTags.pipe(
      filter((tags) => tags != null && tags?.status !== Status.starting),
      map((tags) => tags?.data || [])
    );
  }

  get showWork$(): Observable<boolean> {
    return this.userTags$.pipe(map((tagsData) => !tagsData.some((tag) => tag.tagKey === TagKey.not_working)));
  }

  get showQRCode$(): Observable<boolean> {
    return this.userTags$.pipe(map((tagsData) => !tagsData.some((tag) => tag.tagKey === TagKey.no_QRcode)));
  }

  get showLocalResourcesInPurposeful$(): Observable<boolean> {
    return this.userTags$.pipe(
      map((tagsData) => tagsData.some((tag) => tag.tagKey === TagKey.local_resources_in_purposeful))
    );
  }

  get minimizeLiveSupport$(): Observable<boolean> {
    return this.userTags$.pipe(map((tagsData) => tagsData.some((tag) => tag.tagKey === TagKey.minimize_live_support)));
  }

  get requireResourcefulAccountCreation$(): Observable<boolean> {
    return this.userTags$.pipe(
      map((tagsData) => tagsData.some((tag) => tag.tagKey === TagKey.require_resourceful_account_creation))
    );
  }

  get workbookEligible$(): Observable<boolean> {
    return this.userTags$.pipe(map((tagsData) => tagsData.some((tag) => tag.tagKey === TagKey.workbook_eligible)));
  }

  get languageTranslation$(): Observable<boolean> {
    // using _userTags instead of userTags$ to avoid filter that prevents userTags$ from returning when null
    // so this can be checked when the group/user tags are unknown
    return this._userTags.pipe(
      filter((tags) => tags == null || tags?.status !== Status.starting),
      map((tags) => tags?.data || []),
      map((tagsData) => tagsData.some((tag) => tag.tagKey === TagKey.language_translation))
    );
  }

  get socialChallengeEnabled$(): Observable<boolean> {
    return this.userTags$.pipe(
      map((tagsData) => tagsData.some((tag) => tag.tagKey === TagKey.social_challenges_enabled))
    );
  }

  get generativeAIEnabled$(): Observable<boolean> {
    return this.userTags$.pipe(map((tagsData) => tagsData.some((tag) => tag.tagKey === TagKey.generative_ai_features)));
  }

  get exploratoryGenerativeAIEnabled$(): Observable<boolean> {
    return this.userTags$.pipe(
      map((tagsData) => tagsData.some((tag) => tag.tagKey === TagKey.exploratory_generative_ai))
    );
  }

  getUserTags(): void {
    this._dataStoreService.authData$
      .pipe(
        skipWhile((authData) => !authData?.token),
        take(1)
      )
      .subscribe(() => {
        // Wait until a token is available before making the tags call
        this._userTags.next(new DataStatus<Tag[]>(Status.starting, new StatusMessage(Status.starting, ''), null));
        this._tagApi.getUserTags().then((tags) => {
          this._userTags.next(new DataStatus<Tag[]>(tags ? Status.done : Status.error, tags));
        });
      });
  }

  userHasTagMatch$(tagKey: TagKey, invertMatch = false) {
    return this.userTags$.pipe(
      map((tagsData) => tagsData.some((tag) => (invertMatch ? tag.tagKey !== tagKey : tag.tagKey === tagKey)))
    );
  }
}
