import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { EnvironmentVariablesService } from '@kenv';
import { Product } from '@ktypes/enums';
import { Theme } from '@ktypes/models';
import { getHours } from 'date-fns';
import isEqual from 'lodash/isEqual';
import merge from 'lodash/merge';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { filter, map, pairwise, startWith, switchMap } from 'rxjs/operators';
import { BrowserStorage } from './browser-storage.service';

export interface ThemeStore {
  theme?: Theme;
}

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private _themeWrapper = document.querySelector('body');
  private _defaultNightTheme = new Theme(
    '/assets/shared/background/background-illustration-nightSky.png',
    '#202175',
    '#793682',
    '#202175',
    '#ffffff',
    '#793682',
    '#202175',
    '#202175',
    '/assets/logo/purposeful.svg',
    '/assets/logo/purposeful-with-tag.svg',
    '/assets/logo/purposeful-white.svg',
    '/assets/logo/purposeful-with-tag-white.svg',
    '/assets/shared/background/background-illustration-nightSky-768.png'
  );
  private _defaultDaytimeTheme = new Theme(
    '/assets/shared/background/background-illustration-nightSky.png',
    '#8e2057',
    '#ab392e',
    '#8e2057',
    '#ffffff',
    '#c85204',
    '#ab392e',
    '#8e2057',
    '/assets/logo/purposeful.svg',
    '/assets/logo/purposeful-with-tag.svg',
    '/assets/logo/purposeful-white.svg',
    '/assets/logo/purposeful-with-tag-white.svg',
    '/assets/shared/background/background-illustration-nightSky-768.png'
  );
  private _defaultResourcefulTheme = new Theme(
    '/assets/shared/background/background-illustration-nightSky.png',
    '#0a4f56',
    '#005E5E',
    '#0a4f56',
    '#ffffff',
    '#005E5E',
    '#005E5E',
    '#005A31',
    '/assets/logo/resourceful.svg',
    '/assets/logo/resourceful-with-tag.svg',
    '/assets/logo/resourceful-white.svg',
    '/assets/logo/resourceful-with-tag-white.svg',
    '/assets/shared/background/background-illustration-nightSky-768.png'
  );
  private _defaultHighContrastResourcefulTheme = new Theme(
    '/assets/shared/background/background-illustration-nightSky.png',
    '#0a4f56',
    '#005E5E',
    '#0a4f56',
    '#ffffff',
    '#033031',
    '#033031',
    '#03280C',
    '/assets/logo/resourceful.svg',
    '/assets/logo/resourceful-with-tag.svg',
    '/assets/logo/resourceful-white.svg',
    '/assets/logo/resourceful-with-tag-white.svg',
    '/assets/shared/background/background-illustration-nightSky-768.png'
  );

  constructor(
    private _browserStorage: BrowserStorage,
    private _environmentVariablesService: EnvironmentVariablesService,
    private _router: Router
  ) {
    this._themeStore = {
      theme: new Theme(),
    };
    this._themeStore$ = new BehaviorSubject<ThemeStore>(this._themeStore);

    // on navigation, check for day/night theme
    this._router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      this._checkTheme$.next();
    });

    this._highContrast$
      .pipe(
        startWith(false),
        pairwise(),
        switchMap(([previousValue, currentValue]) => {
          if (!currentValue) {
            this.changeTheme();
            return combineLatest([this._checkTheme$, this._isCustomTheme(), this._defaultDayOrNightChanged()]);
          }
          return of([previousValue, currentValue]);
        })
      )
      .subscribe((args) => {
        if (args.length === 2 && args.every((arg) => arg == null || typeof arg === 'boolean')) {
          const [previousValue, currentValue] = args;
          if (!previousValue && currentValue) {
            const theme =
              this._environmentVariablesService.product === Product.resourceful
                ? this._defaultHighContrastResourcefulTheme
                : this._defaultNightTheme;
            this.changeTheme(theme);
          }
        } else {
          this.changeTheme();
        }
      });
  }

  private _checkTheme$ = new Subject<void>();
  private _highContrast$ = new BehaviorSubject<boolean>(false);
  private _themeStore: ThemeStore = {};
  private _themeStore$: BehaviorSubject<ThemeStore>;

  get theme$(): Observable<Theme> {
    return this._themeStore$.pipe(map((store) => store.theme));
  }

  forceHighContrast(isOn: boolean) {
    this._highContrast$.next(isOn);
  }

  changeTheme(_theme?: any) {
    let theme = new Theme().deserialize(_theme);
    if (theme == null || theme?.primary == null) {
      if (this._environmentVariablesService.product === Product.resourceful) {
        theme = this._defaultResourcefulTheme;
      } else {
        theme = this.useDaytime ? this._defaultDaytimeTheme : this._defaultNightTheme;
      }
    }

    theme.backgroundImageUrl = this._getBackgroundUrlByProperty(theme, 'backgroundImageUrl');
    theme.backgroundImageUrlMobile = this._getBackgroundUrlByProperty(theme, 'backgroundImageUrlMobile');

    if (isEqual(theme, this._themeStore.theme)) {
      // if no change, just exit
      return;
    }

    // Store the new theme
    this.setTheme(theme);

    // Set the current theme variables for CSS use
    this._setThemeWrapperProperty('--backgroundImageUrl', theme.backgroundImageUrl || 'none');
    this._setThemeWrapperProperty('--backgroundImageUrlMobile', theme.backgroundImageUrlMobile || 'none');
    this._setThemeWrapperProperty('--primary', theme.primary);
    this._setThemeWrapperProperty('--primary05', theme.getColorWithTransparency('primary', 0.05));
    this._setThemeWrapperProperty('--primary10', theme.getColorWithTransparency('primary', 0.1));
    this._setThemeWrapperProperty('--primary30', theme.getColorWithTransparency('primary', 0.3));
    this._setThemeWrapperProperty('--primary50', theme.getColorWithTransparency('primary', 0.5));
    this._setThemeWrapperProperty('--primary70', theme.getColorWithTransparency('primary', 0.7));
    this._setThemeWrapperProperty('--primary90', theme.getColorWithTransparency('primary', 0.9));
    this._setThemeWrapperProperty('--secondary', theme.secondary);
    this._setThemeWrapperProperty('--surface', theme.surface);
    this._setThemeWrapperProperty('--surface50', theme.getColorWithTransparency('surface', 0.5));
    this._setThemeWrapperProperty('--onBackground', theme.onBackground);
    this._setThemeWrapperProperty('--onBackground10', theme.getColorWithTransparency('onBackground', 0.1));
    this._setThemeWrapperProperty('--onBackground20', theme.getColorWithTransparency('onBackground', 0.2));
    this._setThemeWrapperProperty('--onBackground30', theme.getColorWithTransparency('onBackground', 0.3));
    this._setThemeWrapperProperty('--onBackground50', theme.getColorWithTransparency('onBackground', 0.5));
    this._setThemeWrapperProperty('--onBackground67', theme.getColorWithTransparency('onBackground', 0.67));
    this._setThemeWrapperProperty('--onBackground80', theme.getColorWithTransparency('onBackground', 0.8));
    this._setThemeWrapperProperty('--onBackground95', theme.getColorWithTransparency('onBackground', 0.95));
    this._setThemeWrapperProperty('--gradientColor1', theme.gradientColor1);
    this._setThemeWrapperProperty('--gradientColor2', theme.gradientColor2);
    this._setThemeWrapperProperty('--gradientColor2_30', theme.getColorWithTransparency('gradientColor2', 0.3));
    // gradientColor3 is optional, default to gradientColor2 if it doesn't exist
    this._setThemeWrapperProperty('--gradientColor3', theme.gradientColor3 || theme.gradientColor2);
    this._setThemeWrapperProperty('--iconUrl', theme.iconUrl);
    this._setThemeWrapperProperty('--iconUrlWithTag', theme.iconUrlWithTag);
    this._setThemeWrapperProperty('--onBackgroundIconUrl', theme.onBackgroundIconUrl);
    this._setThemeWrapperProperty('--onBackgroundIconUrlWithTag', theme.onBackgroundIconUrlWithTag);
  }

  removeTheme() {
    this._themeStore = merge({}, this._themeStore);
    if (this._themeStore.theme) {
      delete this._themeStore.theme;
      this._browserStorage.remove('theme');
    }
  }

  private setTheme(theme: Theme, updateStream = true) {
    // NOTE: use `changeTheme` to update the theme, rather than calling this directly
    if (!theme || isEqual(theme, this._themeStore.theme)) {
      return;
    }
    this._themeStore = {
      ...this._themeStore,
      theme,
    };
    if (updateStream) {
      this._themeStore$.next(this._themeStore);
    }
  }

  private _setThemeWrapperProperty(propertyName: string, value: string) {
    if (value != null) {
      this._themeWrapper.style.setProperty(propertyName, value);
    } else {
      this._themeWrapper.style.removeProperty(propertyName);
    }
  }

  /**
   * useDaytime - Use the daytime theme?
   *
   * 7am to 7pm - Day Theme
   * 7pm to 7am - Night Theme
   */
  get useDaytime(): boolean {
    const hour = getHours(new Date());
    return hour > 6 && hour < 19;
  }

  private async _isCustomTheme() {
    return this.theme$.pipe(
      map((theme) => {
        return (
          theme &&
          ![
            this._defaultDaytimeTheme.primary,
            this._defaultNightTheme.primary,
            this._defaultResourcefulTheme.primary,
            this._defaultHighContrastResourcefulTheme.primary,
          ].includes(theme.primary)
        );
      })
    );
  }

  private _defaultDayOrNightChanged() {
    return this.theme$.pipe(
      map((theme) => {
        return (
          theme.primary !== this._defaultResourcefulTheme.primary &&
          ((theme.primary === this._defaultNightTheme.primary && this.useDaytime) ||
            (theme.primary === this._defaultDaytimeTheme.primary && !this.useDaytime))
        );
      })
    );
  }

  private _getBackgroundUrlByProperty(theme: Theme, property: string): string {
    // @ts-ignore Todo: Revisit to resolve lint issues
    if (theme[property] && !(theme[property] as string).includes('url(')) {
      // @ts-ignore Todo: Revisit to resolve lint issues
      return `url(${theme[property]})`;
      // @ts-ignore Todo: Revisit to resolve lint issues
    } else if (!theme[property]) {
      switch (this._environmentVariablesService.product) {
        case Product.resourceful:
          // @ts-ignore Todo: Revisit to resolve lint issues
          return `url(${this._defaultResourcefulTheme[property]})`;
        case Product.purposeful:
        default:
          // @ts-ignore Todo: Revisit to resolve lint issues
          return `url(${(this.useDaytime ? this._defaultDaytimeTheme : this._defaultNightTheme)[property]})`;
      }
    }
    // @ts-ignore Todo: Revisit to resolve lint issues
    return theme[property] as string;
  }
}
