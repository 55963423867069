import { Component, EventEmitter, Output } from '@angular/core';
import { NotificationPermissionStatus, NotificationsBloc } from '@kp/notifications/notifications.bloc';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'kp-modal-enable-notifications',
  templateUrl: './modal-enable-notifications.component.html',
  styleUrls: ['./modal-enable-notifications.component.scss'],
})
export class ModalEnableNotificationsComponent {
  @Output() closeClicked = new EventEmitter<NotificationPermissionStatus>();
  showInitialCase = true;
  showNotNowCase = false;

  constructor(private _notificationsBloc: NotificationsBloc) {}

  notNow() {
    this.showInitialCase = false;
    this.showNotNowCase = true;
  }

  dismissModal() {
    this.showNotNowCase = false;
    this.closeClicked.emit(NotificationPermissionStatus.notNow);
  }

  async enable() {
    await this._notificationsBloc.requestPermission();
    this.closeClicked.emit(NotificationPermissionStatus.enabled);
  }
}

export const MockModalEnableNotificationsComponent = MockComponent({
  selector: 'kp-modal-enable-notifications',
});
