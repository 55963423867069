import { Inject, Injectable } from '@angular/core';
import { GroupApi, UserApi } from '@kapi';
import { AuthenticationApi, BaseAuthenticationBloc } from '@kauth';
import { FirstVisitBloc, LandingDynamicBloc, TagBloc } from '@kbloc';
import { EnvironmentVariablesService } from '@kenv';
import { TranslationBloc } from '@kf-loc';
import { AuthDataService, BrowserStorage, DataStoreService, OnboardingUtilities, WINDOW } from '@kservice';
import { Credentials, JsonObject } from '@ktypes/models';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationBloc extends BaseAuthenticationBloc {
  constructor(
    _authDataService: AuthDataService,
    _authenticationApi: AuthenticationApi,
    public browserStorage: BrowserStorage,
    _dataStoreService: DataStoreService,
    _environmentVariablesService: EnvironmentVariablesService,
    private _firstVisitBloc: FirstVisitBloc,
    _groupApi: GroupApi,
    private _landingDynamicBloc: LandingDynamicBloc,
    public onboardingUtilities: OnboardingUtilities,
    private _tagBloc: TagBloc,
    private _translationBloc: TranslationBloc,
    _userApi: UserApi,
    @Inject(WINDOW) _window: Window
  ) {
    super(
      _authDataService,
      _authenticationApi,
      browserStorage,
      _dataStoreService,
      _environmentVariablesService,
      _groupApi,
      onboardingUtilities,
      _userApi,
      _window
    );
  }

  CODE_MISMATCH_ERROR = 'The code you entered was incorrect.';

  // Do login operation
  login(credentials?: Credentials, errorFunction?: () => void, successFunction?: () => void): void {
    super.login(credentials, errorFunction, successFunction || this._loginSuccess.bind(this));
  }

  postRegisterLogin(credentials: Credentials) {
    super.postRegisterLogin(credentials);
  }

  clearAuthData() {
    super.clearAuthData();
    this._firstVisitBloc.resetCachedFirstVisit();
  }

  saveExistingAuthData(pulseSurveyAuthData: JsonObject) {
    super.saveExistingAuthData(pulseSurveyAuthData, this._saveExistingAuthDataSuccess.bind(this));
  }

  getAuthErrorMessage(statusMessage: string, defaultError: string = null): string {
    // These are error messages that can come during the signup or login process
    const authErrorMessages: JsonObject<string> = {
      'Bad Request': 'There was an error, please try again or contact support for assistance.',
      'CodeMismatchException': this.CODE_MISMATCH_ERROR,
      'CodeDeliveryFailureException': this.CODE_MISMATCH_ERROR,
      'ExpiredCodeException': 'The code you entered has expired.',
      'InvalidPasswordException': 'The password you entered is not secure enough. Please try a different password.',
      'LimitExceededException': 'Too many login attempts for that email.',
      'PasswordResetRequiredException': 'A password reset is required for your account.',
      'UsernameExistsException': "The email and password you entered don't match our records. Please try again.",
    };
    return authErrorMessages[statusMessage] ?? defaultError;
  }

  private _loginSuccess() {
    this.onboardingUtilities.cleanupOnboardingStorage();
    this._landingDynamicBloc.resetLanding();
    this._firstVisitBloc.resetCachedFirstVisit();

    this._tagBloc.languageTranslation$.pipe(take(1)).subscribe((languageTranslation) => {
      if (languageTranslation) {
        this._translationBloc.checkUserLanguage();
      }
    });
  }

  private _saveExistingAuthDataSuccess() {
    this.onboardingUtilities.cleanupOnboardingStorage();
  }
}
