import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FirstVisitBloc } from '@kbloc';
import {
  NotificationPermissionState,
  NotificationPermissionStatus,
  NotificationsBloc,
} from '@kp/notifications/notifications.bloc';
import { CardBloc } from '@kp/shared/components/cards/card.bloc';
import { Constants } from '@kp/shared/constants.service';
import { WINDOW } from '@kservice';
import { FirstVisitCase } from '@ktypes/enums';
import { CardItem, NotificationDevice, NotificationInfo, Status } from '@ktypes/models';
import { MockComponent } from '@kutil/test';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'kp-action-reminder-detailed-view',
  templateUrl: './action-reminder-detailed-view.component.html',
})
export class ActionReminderDetailedViewComponent implements OnInit, OnDestroy {
  constructor(
    private _cardBloc: CardBloc,
    @Inject(DOCUMENT) private _document: Document,
    private _firstVisitBloc: FirstVisitBloc,
    private _notificationsBloc: NotificationsBloc,
    private _renderer: Renderer2,
    @Inject(WINDOW) private _window: Window
  ) {}

  @Input() card: CardItem;
  notifications: NotificationInfo[];
  primaryIcon: string;
  setRemindersOn = false;
  showBrowserUnsupported = false;
  showBrowserNotificationDialog = false;
  showPflNotificationDialog = false;
  showRemindersModal = false;

  readonly addIcon = 'assets/icons/plus-circle.svg';
  readonly cancelIcon = 'assets/icons/x-circle.svg';

  private _destroy$ = new Subject<void>();
  private _devices: NotificationDevice[];
  private _devicesSubscriptionSetup = false;

  ngOnInit(): void {
    this._initializeNotifications();
    this._firstVisitBloc.hasSeen$.pipe(take(1)).subscribe((firstVisit) => {
      if (!firstVisit.shownNotificationsModal) {
        this._firstVisitBloc.updateFirstVisit(FirstVisitCase.shownNotificationsModal);
      }
    });
    this.notifications = this.card?.userState?.notifications?.filter((notification) => notification.id);
    this.handlePrimaryIconChange();
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  isRemindersModalOpen() {
    this._renderer.removeClass(this._document.documentElement, 'no-scroll');
    this.handlePermissionsFromModal(NotificationPermissionStatus.notNow);
  }

  handlePermissionsFromModal(permissionStatus: NotificationPermissionStatus) {
    this.showRemindersModal = false;
    if (permissionStatus === NotificationPermissionStatus.enabled) {
      this.toggleWebReminders(true);
    }
  }

  private _initializeNotifications() {
    this._notificationsBloc.getDevices();
    this._checkNotificationAbilities();
  }

  private _checkNotificationAbilities() {
    if (!this._notificationsBloc.isSupported()) {
      this.showBrowserUnsupported = true;
    } else {
      switch (this._window.Notification?.permission) {
        case NotificationPermissionState.default:
          this.showPflNotificationDialog = true;
          break;
        case NotificationPermissionState.granted:
          this.showPflNotificationDialog = false;
          this._setupDevicesSubscription();
          break;
        case NotificationPermissionState.denied:
          this.showBrowserNotificationDialog = true;
          break;
      }
    }
  }

  private _setupDevicesSubscription() {
    if (!this._devicesSubscriptionSetup) {
      this._devicesSubscriptionSetup = true;
      this._notificationsBloc.devicesSubject$.pipe(takeUntil(this._destroy$)).subscribe((devices) => {
        if (devices?.status === Status.done) {
          this._devices = devices.data;
          this.setRemindersOn =
            this._window.Notification?.permission === NotificationPermissionState.granted &&
            this._devices?.some((device) => device.platformName === Constants.platformName);
          if (!this.setRemindersOn) {
            this.showPflNotificationDialog = true;
          }
        }
      });
    }
  }

  primaryIconClick() {
    if (this._isNotEditing()) {
      this.addNotification();
    } else {
      this.notifications = this.card.userState.notifications
        ?.filter((notification) => notification.id)
        ?.map((notification) => {
          notification.isEditing = false;
          return notification;
        });
    }
    this.handlePrimaryIconChange();
  }

  addNotification() {
    if (this.notifications) {
      this.notifications.push(new NotificationInfo().deserialize({ isEditing: true }));
    } else {
      this.notifications = [new NotificationInfo().deserialize({ isEditing: true })];
    }
  }

  toggleWebReminders(isChecked: boolean) {
    if (isChecked && this._window.Notification?.permission === NotificationPermissionState.default) {
      this.showRemindersModal = true;
    } else if (isChecked && this._window.Notification?.permission === NotificationPermissionState.granted) {
      this.setRemindersOn = true;
      this._requestMessagingToken();
      this.showPflNotificationDialog = false;
    } else if (isChecked && this._window.Notification?.permission === NotificationPermissionState.denied) {
      this.showPflNotificationDialog = false;
      this.showBrowserNotificationDialog = true;
    } else {
      if (this._devices) {
        this._notificationsBloc.deleteWebDevices(this._devices);
      }
      this.setRemindersOn = false;
    }
  }

  private _requestMessagingToken() {
    void this._notificationsBloc.requestPermission()?.then((permission) => {
      if (permission === NotificationPermissionState.denied) {
        this.setRemindersOn = false;
      }
    });
  }

  handleNotificationDeletion(notification: NotificationInfo) {
    this.notifications = this.notifications.filter((originalNotification) => originalNotification !== notification);
    this._cardBloc.handleCardNotifications(this.card.id, this.notifications);
    this.handlePrimaryIconChange();
  }

  handleNotificationSave() {
    this._cardBloc.handleCardNotifications(this.card.id, this.notifications);
    this.handlePrimaryIconChange();
  }

  handlePrimaryIconChange() {
    this.primaryIcon = this._isNotEditing() ? this.addIcon : this.cancelIcon;
  }

  private _isNotEditing(): boolean {
    return (
      this.notifications?.filter((notification) => !notification?.id || (notification?.id && notification.isEditing))
        .length === 0 || !this.notifications
    );
  }
}

export const MockActionReminderDetailedViewComponent = MockComponent({
  selector: 'kp-action-reminder-detailed-view',
  inputs: ['card'],
});
