import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService } from '@kservice';
import { HttpStatusCode } from '@ktypes/enums';
import { DataStatus, LandingPage, Status, StatusMessage } from '@ktypes/models';
import { firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseApi, RequestType } from './base.api';

@Injectable({
  providedIn: 'root',
})
export class LandingDynamicApi extends BaseApi {
  constructor(
    private _client: HttpClient,
    dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(_client, dataStoreService, _environmentVariablesService);
  }

  getLandingPage(groupCode: string, product?: string): Promise<DataStatus<LandingPage>> {
    const url = this.buildUrl(`/group/${groupCode}/landing`);
    let headers: HttpHeaders;
    if (product) {
      headers = new HttpHeaders({ product });
    }
    const request = this.performRequest<LandingPage>(RequestType.GET, url, { headers }).pipe(
      map(
        (response: HttpResponse<LandingPage>): DataStatus<LandingPage> =>
          new DataStatus<LandingPage>(
            response?.ok ? Status.done : Status.error,
            response?.ok
              ? new StatusMessage(HttpStatusCode.OK, 'OK')
              : new StatusMessage(HttpStatusCode.INTERNAL_SERVER_ERROR, 'Error getting landing page.'),
            new LandingPage().deserialize(response?.body)
          )
      ),
      catchError(handleObservableError('Error getting landing page: '))
    );

    return firstValueFrom(request).catch(handleError('Error getting landing page: '));
  }
}

function handleObservableError(errorMessage: string) {
  return (error: HttpErrorResponse) => {
    console.warn(errorMessage, error);
    return of(
      new DataStatus<LandingPage>(Status.error, new StatusMessage(error.status, error?.message ?? errorMessage), null)
    );
  };
}

function handleError(errorMessage: string) {
  return (error: HttpErrorResponse) => {
    console.warn(errorMessage, error);
    return new DataStatus<LandingPage>(Status.error, new StatusMessage(error.status, errorMessage), null);
  };
}
