import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { LandingSection } from './landing-section.model';

export class LandingPage implements Deserializable<JsonObject, LandingPage> {
  constructor(
    public pulseSurveyId?: string,
    public content?: LandingSection[]
  ) {}

  deserialize(input: JsonObject): LandingPage {
    if (input == null) {
      return null;
    }

    this.pulseSurveyId = input['pulseSurveyId'] as string;

    if (Array.isArray(input['content'])) {
      this.content = input['content'].map((section) => new LandingSection().deserialize(section));
    }

    return this;
  }
}
