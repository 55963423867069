import { Injectable } from '@angular/core';
import { SettingsApi } from '@kapi';
import { DataStoreService } from '@kservice';
import { JsonObject, Setting, Settings } from '@ktypes/models';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SettingsBloc {
  constructor(
    private _dataStoreService: DataStoreService,
    private _settingsApi: SettingsApi
  ) {}

  private _userSubscription: Subscription;
  private _userDestroyer$ = new Subject<void>();

  getUserSettings() {
    this._settingsApi
      .getUserSettings()
      .then((settings) => {
        if (settings instanceof Settings) {
          this._dataStoreService.setUserSettings(settings);
        }
      })
      .catch((error) => {
        console.warn('Error getting user settings: ', error);
      });
  }

  changeUserSetting(setting: Setting, value: boolean | string) {
    if (setting === Setting.staySignedIn && typeof value === 'boolean') {
      this._dataStoreService.setLoggedIn(value);
    } else {
      const requestBody: JsonObject = {
        [setting]: value,
      };
      this._settingsApi
        .changeUserSetting(requestBody)
        .then((settings) => {
          if (settings instanceof Settings) {
            this._dataStoreService.setUserSettings(settings);
          }
        })
        .catch((error) => {
          console.warn('Error getting user settings: ', error);
        });
    }
  }

  seenAutoAdvanceSettings() {
    this._userSubscription?.unsubscribe();
    this._userSubscription = this._dataStoreService.user$.pipe(takeUntil(this._userDestroyer$)).subscribe((user) => {
      if (user.settings?.autoAdvance == null) {
        this.changeUserSetting(Setting.autoAdvance, true);
      }
      this._userDestroyer$.next();
    });
  }
}
