export enum AnalyticsPageName {
  account = 'account',
  accountDeletion = 'accountDeletion',
  accountDeletionConfirmation = 'accountDeletionConfirmation',
  act = 'act',
  cardDetail = 'cardDetail',
  challenges = 'challenges',
  challengeDetail = 'challengeDetail',
  changeEmail = 'changeEmail',
  changeNickname = 'changeNickname',
  compare = 'compare',
  confirmEmail = 'confirmEmail',
  confirmEmailResendCode = 'confirmEmailResendCode',
  confirmNewEmail = 'confirmNewEmail',
  confirmNewEmailResendCode = 'confirmNewEmailResendCode',
  dashboard = 'dashboard',
  dataSource = 'dataSource',
  dialogue = 'dialogue',
  discover = 'discover',
  eligibility = 'eligibility',
  eula = 'eula',
  feedback = 'feedback',
  forgotPassword = 'forgotPassword',
  goOutAndLive = 'goOutAndLive',
  help = 'help',
  historyDay = 'historyDay',
  historyMonth = 'historyMonth',
  home = 'home',
  invite = 'invite',
  landing = 'landing',
  legalDocAcceptance = 'legalDocAcceptance',
  login = 'login',
  loginEmail = 'loginEmail',
  loginResetCode = 'loginResetCode',
  loginPassword = 'loginPassword',
  logout = 'logout',
  meView = 'meView',
  meEdit = 'meEdit',
  mobileAppInfo = 'mobileAppInfo',
  mobileAppInterstitial = 'mobileAppInterstitial',
  myPhotos = 'myPhotos',
  onboarding = 'onboarding',
  onboardingDialogue = 'onboardingDialogue',
  onboardingLegalDocAcceptance = 'onboardingLegalDocAcceptance',
  postReflection = 'postReflection',
  privacy = 'privacy',
  privacyPledge = 'privacyPledge',
  progress = 'progress',
  pulseSurveyIntroScreen = 'pulseSurveyIntroScreen',
  purposefulDays = 'purposefulDaysExplanation',
  quest = 'quest',
  questionSet = 'questionSet',
  reflect = 'reflect',
  reminders = 'reminders',
  report = 'report',
  reports = 'reports',
  resetPassword = 'resetPassword',
  resources = 'resources',
  saved = 'saved',
  search = 'search',
  setPassword = 'setPassword',
  settings = 'settings',
  signup = 'signup',
  signupEmail = 'signupEmail',
  singlePhoto = 'singlePhoto',
  siteMap = 'siteMap',
  takeAction = 'takenAction',
  test = 'test',
  today = 'today',
  welcome = 'welcome',
  workbook = 'workbook',
}
