import { Pipe, PipeTransform } from '@angular/core';
import { TagBloc } from '@kbloc';
import { SharedConstants } from '@kservice';
import { MockAsyncPipe } from '@kutil/test';
import { Observable, of, skip, withLatestFrom } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { TranslationBloc } from './translation.bloc';

@Pipe({
  name: 'translate',
  standalone: true,
})
export class TranslatePipe implements PipeTransform {
  constructor(
    private _tagBloc: TagBloc,
    private _translationBloc: TranslationBloc
  ) {}

  private _currentLanguage: string;

  transform(text: string | number, format: 'html' | 'text' = 'text'): Observable<string> {
    if (text == null || (typeof text === 'string' && text.trim() === '')) {
      return of('');
    }
    const normalizedText = `${text}`;
    return (
      this._tagBloc.languageTranslation$?.pipe(
        switchMap((allowsLanguageTranslation) => {
          if (!allowsLanguageTranslation) {
            return of(normalizedText);
          }
          return this._translationBloc.currentLanguage$.pipe(
            withLatestFrom(this._translationBloc.translationsMap$),
            switchMap(async ([currentLanguage, translationsMap]) => {
              this._currentLanguage = currentLanguage;
              // Don't translate if not needed
              if (!currentLanguage || currentLanguage === SharedConstants.DEFAULT_LANGUAGE) {
                return of(normalizedText);
              }
              // return from cache if already exists
              const cachedTranslation = translationsMap.get(
                await this._translationBloc.getHashedKey(this._currentLanguage, normalizedText)
              );
              if (cachedTranslation?.translatedText) {
                return of(cachedTranslation.translatedText);
              }
              this._translationBloc.batchTranslations(normalizedText, currentLanguage);
              // This needs to skip 1 to skip the current behaviorSubject value waiting on batchTranslations & translation API functionality
              return this._translationBloc.translationsMap$.pipe(skip(1));
            }),
            // convert promise back to observable
            switchMap((translationOrTranslationsMap) => translationOrTranslationsMap),
            map(async (translationOrTranslationsMap) => {
              // Comes through as string if didn't need to translate
              if (typeof translationOrTranslationsMap === 'string') {
                return translationOrTranslationsMap;
              }
              const translation = translationOrTranslationsMap.get(
                await this._translationBloc.getHashedKey(this._currentLanguage, normalizedText)
              );
              return translation?.translatedText || normalizedText;
            }),
            // convert promise back to observable
            switchMap((translationOrTranslationsMap) => translationOrTranslationsMap)
          );
        })
      ) || of(normalizedText)
    );
  }
}

export const MockTranslatePipe = MockAsyncPipe('translate');
