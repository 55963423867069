import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AnalyticEvent, AnalyticsBloc } from '@kanalytics';
import { FirstVisitBloc, SettingsBloc, SharingBloc, TagBloc } from '@kbloc';
import { EnvironmentVariablesService } from '@kenv';
import { TranslationBloc } from '@kf-loc';
import { NotificationsBloc } from '@kp/notifications/notifications.bloc';
import { DailyChallengeStatusBloc } from '@kp/shared/components/daily-challenge/daily-challenge-status.bloc';
import { UserBloc } from '@kp/user/user.bloc';
import { VersionBloc } from '@kp/version/version.bloc';
import { ThemeService, WINDOW } from '@kservice';
import { FirstVisitCase, Product } from '@ktypes/enums';
import { NavigationService } from '@kutil';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppLifecycleService {
  constructor(
    private _analyticsBloc: AnalyticsBloc,
    private _dailyChallengeStatusBloc: DailyChallengeStatusBloc,
    private _environmentVariablesService: EnvironmentVariablesService,
    private _firstVisitBloc: FirstVisitBloc,
    navigation: NavigationService, // do not delete, triggers NavigationService tracking navigation
    notificationsBloc: NotificationsBloc, // do not delete, sets up listener for web service token changes
    private _router: Router,
    private _settingsBloc: SettingsBloc,
    private _sharingBloc: SharingBloc,
    private _tagBloc: TagBloc,
    themeService: ThemeService, // do not delete, here to trigger ThemeService init
    private _translationBloc: TranslationBloc,
    private _userBloc: UserBloc,
    private _versionBloc: VersionBloc,
    @Inject(WINDOW) private _window: Window
  ) {}

  private _destroy$ = new Subject<void>();

  startup() {
    // stop supported browser timer from timing out and triggering unsupported browser page
    this._window['isSupportedBrowser'] = true;

    if (this._window.location.href.includes('/delete') || this._window.location.href.includes('/unsubscribe')) {
      // if on delete or unsubscribe path, don't kick off lifecycle events
      return;
    }
    // kick off analytics
    this._analyticsBloc.initialize();

    this._analyticsBloc.sendEvent(
      this._analyticsBloc.createEventFromEvent({
        event: AnalyticEvent.app_open,
      })
    );

    this._settingsBloc.getUserSettings();

    this._tagBloc.getUserTags();

    this._versionBloc.checkAppVersion();

    // ENTER/EXIT PAGE: subscribe to router page visit nav end events and check required app version(s)
    this._router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(this._pageChange.bind(this));

    this._sharingBloc.getInvites();

    this._firstVisitBloc.hasSeen$.pipe(takeUntil(this._destroy$)).subscribe((hasSeen) => {
      if (!hasSeen.firstTimeAppVisit && this._firstVisitBloc.firstCallMade) {
        this._userBloc.navigatedFirstTimeToApp(this._environmentVariablesService.product as Product);
        this._firstVisitBloc.updateFirstVisit(FirstVisitCase.firstTimeAppVisit);
      }
    });

    this._translationBloc.getSupportedLanguages();
    this._tagBloc.languageTranslation$.pipe(takeUntil(this._destroy$), take(1)).subscribe((languageTranslation) => {
      if (languageTranslation) {
        this._translationBloc.checkUserLanguage();
      }
    });
  }

  private _pageChange() {
    this._versionBloc.checkAppVersion();
  }

  shutdown() {
    this._destroy$.next();
    this._destroy$.complete();
    if (this._window.location.href.includes('/delete') || this._window.location.href.includes('/unsubscribe')) {
      // if on delete or unsubscribe path, lifecycle events where never initialized, just exit
      return;
    }
    this._analyticsBloc.sendEvent(
      this._analyticsBloc.createEventFromEvent({
        event: AnalyticEvent.app_exit,
      })
    );
  }
}
