import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, NgZone } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AnalyticsBloc, PageMappingInfo } from '@kanalytics';
import { EnvironmentVariablesService } from '@kenv';
import { AuthenticationBloc } from '@kp/auth/authentication.bloc';
import { PulseSurveyBloc } from '@kp/pulse-survey/pulse-survey.bloc';
import { CardBloc } from '@kp/shared/components/cards/card.bloc';
import { UserBloc } from '@kp/user/user.bloc';
import { DataStoreService, WINDOW } from '@kservice';
import { Product } from '@ktypes/enums';
import { UserIdleService } from 'angular-user-idle';
import { BehaviorSubject, Observable, Subject, Subscription, combineLatest, finalize, withLatestFrom } from 'rxjs';
import { distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';

let initialBrowserLoad = true;

@Injectable({
  providedIn: 'root',
})
export class GlobalElementsService {
  constructor(
    private _analyticsBloc: AnalyticsBloc,
    private _authenticationBloc: AuthenticationBloc,
    private _cardBloc: CardBloc,
    private _dataStoreService: DataStoreService,
    @Inject(DOCUMENT) private _document: Document,
    private _environmentVariablesService: EnvironmentVariablesService,
    private _pulseSurveyBloc: PulseSurveyBloc,
    private _router: Router,
    private _userBloc: UserBloc,
    private _userIdleService: UserIdleService,
    @Inject(WINDOW) private _window: Window,
    private _ngZone: NgZone
  ) {
    // Check routes on route changes and show/hide elements per route
    this._router.events
      .pipe(filter((event: Event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((navEvent: NavigationEnd) => {
        this._routeCheck(navEvent.urlAfterRedirects);
        this._updateFocusPostNav(navEvent.urlAfterRedirects);
      });
    // Check routes on route start and update user if necessary
    this._router.events
      .pipe(
        filter((event: Event): event is NavigationStart => event instanceof NavigationStart),
        withLatestFrom(this._dataStoreService.user$)
      )
      .subscribe(([_, user]) => {
        if (user?.id && !user?.hasLoadedData) {
          this._userBloc.getUser(user.id);
        }
      });
  }

  // Note: these arrays support regex matching for partial matching. For example,
  // '/welcome' will match all routes that contain /welcome (including
  // /welcome/privacy, /welcome/error, etc).
  routesToHideAllChrome = [
    '/auth',
    '/error/update',
    '/login(?!/(existing_account|reset))',
    '/welcome/signup$',
    '/welcome$',
    '/welcome#.*',
    '/initial.*',
  ];
  routesToHideHeader: string[] = [];
  routesToHideNav = ['/dialogue', '/error/update', '/pulse_survey', '/report/.*', '/welcome/.*'];
  routesToShowBasicLogoHeader = [
    '/access-expired',
    '/data-link/error',
    '/delete',
    '/dialogue',
    '/error/update',
    '/login/existing_account',
    '/privacy-full',
    '/privacy-pledge',
    '/pulse_survey',
    '/question_set',
    '/question',
    '/report/.*',
    '/resources',
    '/thank_you',
    '/unsubscribe',
    '/welcome/.*',
    'welcome/error',
    '/login/reset/.*',
  ];
  routesToShowBasicLogoHeaderWithIcons = ['/dialogue', '/privacy-pledge', '/pulse_survey', '/report/.*', '/resources'];
  routesToShowFullHeaderToResourcefulUsers = ['/report', '/resources'];
  routesToShowAccountCreationBanner = ['/resources'];
  routesToHideLiveSupport = ['/login', '/welcome/error'];
  routesToShowFeatureFilter = ['/today', '/cards/take-action'];

  private _subscriptions: Subscription[] = [];

  private _showChrome = new BehaviorSubject<boolean>(false);
  get showChrome$(): Observable<boolean> {
    return this._showChrome.asObservable();
  }

  private _showHeader = new BehaviorSubject<boolean>(false);
  get showHeader$(): Observable<boolean> {
    return this._showHeader.asObservable();
  }

  private _showNav = new BehaviorSubject<boolean>(false);
  get showNav$(): Observable<boolean> {
    return this._showNav.asObservable();
  }

  private _showBasicLogoHeader = new BehaviorSubject<boolean>(false);
  get showBasicLogoHeader$(): Observable<boolean> {
    return this._showBasicLogoHeader.asObservable();
  }

  private _showBasicLogoHeaderWithIcons = new BehaviorSubject<boolean>(false);
  get showBasicLogoHeaderWithIcons$(): Observable<boolean> {
    return this._showBasicLogoHeaderWithIcons.asObservable();
  }

  private _showAccountCreationBanner$ = new BehaviorSubject<boolean>(false);
  get showAccountCreationBanner$(): Observable<boolean> {
    return this._showAccountCreationBanner$.asObservable();
  }

  private _showLiveSupport$ = new BehaviorSubject<boolean>(false);

  showLiveSupport$: Observable<boolean> = combineLatest([
    this._showLiveSupport$.asObservable(),
    this._userBloc.liveSupport$,
  ]).pipe(
    map(([showLiveSupport, liveSupport]) => {
      return showLiveSupport && liveSupport != null;
    }),
    distinctUntilChanged()
  );

  private _showFeatureFilter$ = new BehaviorSubject<boolean>(false);
  get showFeatureFilter$(): Observable<boolean> {
    return this._showFeatureFilter$.asObservable();
  }

  private _currentPageInfo$ = new BehaviorSubject<PageMappingInfo>(null);
  get currentPageInfo$(): Observable<PageMappingInfo> {
    return this._currentPageInfo$.asObservable();
  }

  watchIdleUser(isTodayPage?: boolean) {
    // only enable Idle User watcher for Purposeful for now
    if (this._authenticationBloc.isLoggedIn() && this._environmentVariablesService.product === Product.purposeful) {
      const stayLoggedIn = this._dataStoreService.stayLoggedIn;
      //Start watching for user inactivity.
      this._userIdleService.startWatching();
      const timerStartSub = this._userIdleService.onTimerStart().subscribe();
      const timeoutSub = this._userIdleService.onTimeout().subscribe((_: boolean) => {
        if (stayLoggedIn) {
          this._pulseSurveyBloc.allowPulseSurveyCheckToday(true);
          this._userIdleService.resetTimer();
          if (isTodayPage === true) {
            return (window.location.href = '');
          }
          return this._router.navigate(['']);
        } else {
          this._authenticationBloc.logout();
        }
        return null;
      });
      this._subscriptions.push(timerStartSub, timeoutSub);
    }
  }

  stopWatchingIdleUser(): void {
    this._subscriptions.forEach((sub) => sub.unsubscribe());
  }

  private _isResourcefulUser(): boolean {
    return this._environmentVariablesService.product === Product.resourceful && this._authenticationBloc.isLoggedIn();
  }

  private _routeCheck(url: string) {
    const urlHasMatch = createUrlHasMatch(url);

    const showBasicLogoHeader =
      urlHasMatch(this.routesToShowBasicLogoHeader) &&
      !urlHasMatch(this.routesToHideAllChrome) &&
      !(this._isResourcefulUser() && urlHasMatch(this.routesToShowFullHeaderToResourcefulUsers));
    const showBasicLogoHeaderWithIcons =
      urlHasMatch(this.routesToShowBasicLogoHeaderWithIcons) && !urlHasMatch(this.routesToHideAllChrome);
    const showChrome = !showBasicLogoHeader && !urlHasMatch(this.routesToHideAllChrome);
    const showHeader =
      (showChrome && !urlHasMatch(this.routesToHideHeader)) ||
      (this._isResourcefulUser() && urlHasMatch(this.routesToShowFullHeaderToResourcefulUsers));
    const showNav =
      (showChrome && !urlHasMatch(this.routesToHideNav)) ||
      (this._isResourcefulUser() && urlHasMatch(this.routesToShowFullHeaderToResourcefulUsers));
    const showAccountCreationBanner =
      urlHasMatch(this.routesToShowAccountCreationBanner) && !this._authenticationBloc.isLoggedIn();
    const showLiveSupport = !urlHasMatch(this.routesToHideLiveSupport);
    const showFeatureFilter = urlHasMatch(this.routesToShowFeatureFilter);

    this._showHeader.next(showHeader);
    this._showNav.next(showNav);
    this._showChrome.next(showChrome);
    this._showBasicLogoHeader.next(showBasicLogoHeader);
    this._showBasicLogoHeaderWithIcons.next(showBasicLogoHeaderWithIcons);
    this._showAccountCreationBanner$.next(showAccountCreationBanner);
    this._showLiveSupport$.next(showLiveSupport);
    this._showFeatureFilter$.next(showFeatureFilter);
    this._cardBloc.detailViewClicked.next({ detailViewClicked: false, card: null, screen: null });
  }

  private _updateFocusPostNav(url: string) {
    const elementFound$ = new Subject<void>();
    const pageInfo = this._analyticsBloc.getDynamicPageInfoIfMatch(url);

    // Update page info observable on navigating (for announcing page changes and focusing proper element on navigation)
    this._currentPageInfo$.next(pageInfo);

    // Reset scroll position when navigating (helps on mobile when screens get tall)
    this._window.scrollTo(0, 0);

    // using onMicrotaskEmpty to check for focusElement as page finishes rendering; allowing up to 25 passes
    // to allow for child component to complete rendering. Will fall back to default focus if hits limit.
    // Using finalize to prevent possible memory leaks for elementFound$ Subject
    this._ngZone.onMicrotaskEmpty
      .pipe(
        take(25),
        takeUntil(elementFound$),
        finalize(() => {
          if (!elementFound$.closed) {
            elementFound$.complete();
          }
        })
      )
      .subscribe(() => {
        // Find / focus back to top of page; before nav (#browser-nav-focus) on initial load,
        // otherwise the focusElementSelector if defined or h1, otherwise #nav-focus (just inside #main)
        let defaultNavFocus = false;
        let navFocus: HTMLElement = initialBrowserLoad
          ? this._document.getElementById('browser-nav-focus')
          : this._document.querySelector(pageInfo?.meta?.focusElementSelector || 'h1');
        if (!navFocus) {
          navFocus = this._document.getElementById('nav-focus');
          defaultNavFocus = true;
        }
        if (navFocus) {
          // set tabindex -1 so element is focusable
          navFocus.setAttribute('tabindex', '-1');
          navFocus.focus();

          if (!defaultNavFocus && !elementFound$.closed) {
            elementFound$.next();
            elementFound$.complete();
          }
        }
        if (initialBrowserLoad) {
          initialBrowserLoad = false;
        }
      });
  }
}

function createUrlHasMatch(url: string) {
  return (routes: string[]) => routes.some((route) => url.match(RegExp(route)));
}
