import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { Serializable } from './serializable.model';
import { TagAttribute } from './tag-attribute.model';
import { TagRole } from './tag-role.model';

export enum AttributeKey {
  daily_challenge_feature_flag = 'daily_challenge_feature_flag',
  deprioritize_work_content = 'deprioritize_work_content',
  focus_enabled = 'focus_enabled',
  hide_work_ui_elements = 'hide_work_ui_elements',
  local_resources_in_purposeful = 'local_resources_in_purposeful',
  minimize_live_support = 'minimize_live_support',
  require_resourceful_account_creation = 'require_resourceful_account_creation',
  social_challenges_enabled = 'social_challenges_enabled',
  workbook_eligible = 'workbook_eligible',
}

export enum RoleKey {
  default = 'default',
  eligibility_attribute = 'eligibility_attribute',
  probabilistic = 'probabilistic',
  user_defined = 'user_defined',
}

export enum TagKey {
  exploratory_generative_ai = 'exploratory_generative_ai',
  focus_enabled = 'focus_enabled',
  generative_ai_features = 'generative_ai_features',
  language_translation = 'language_translation',
  local_resources_in_purposeful = 'local_resources_in_purposeful',
  minimize_live_support = 'minimize_live_support',
  no_QRcode = 'no_QRcode',
  not_working = 'not_working',
  require_resourceful_account_creation = 'require_resourceful_account_creation',
  social_challenges_enabled = 'social_challenges_enabled',
  workbook_eligible = 'workbook_eligible',
}

export class Tag implements Deserializable<JsonObject, Tag>, Serializable<JsonObject> {
  constructor(
    public id?: string,
    public tagKey?: TagKey,
    public tagAttributes?: TagAttribute[],
    public tagRoles?: TagRole[]
  ) {}

  deserialize(input: JsonObject): Tag {
    if (input == null) {
      return null;
    }
    this.id = input['id'] as string;
    this.tagKey = input['tagKey'] as TagKey;
    this.tagAttributes =
      (input['tagAttributes'] as TagAttribute[])?.map?.((tagAttribute) =>
        new TagAttribute().deserialize(tagAttribute)
      ) || [];
    this.tagRoles = (input['tagRoles'] as TagRole[])?.map?.((tagRole) => new TagRole().deserialize(tagRole)) || [];

    return this;
  }

  serialize(): JsonObject {
    const json: JsonObject = {};
    json['id'] = this.id;
    json['tagKey'] = this.tagKey;
    json['tagAttributes'] = this.tagAttributes?.map?.((tagAttribute) => tagAttribute.serialize());
    json['tagRoles'] = this.tagRoles?.map?.((tagRole) => tagRole.serialize());

    return json;
  }
}
