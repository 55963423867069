<div class="celebration-content">
  @if (socialChallengeBloc.celebrationModalChallenge$ | async; as challenge) {
    <h2 class="celebration-title">{{ challenge.title | translate | async }} {{ 'Challenge' | translate | async }}</h2>
    <kf-sc-social-challenge-progress
      class="celebration-paths"
      [groupGoal]="challenge.group.goal"
      [initGroupProgress]="challenge.group.status.progress.count / 2"
      [groupProgress]="challenge.group.status.progress.count"
      [individualGoal]="challenge.individual.goal"
      [initIndividualProgress]="challenge.individual.status.progress.count - 1"
      [individualProgress]="challenge.individual.status.progress.count"
      [centerPathRef]="centerPath.EMPTY"
      [isOnBackground]="true"></kf-sc-social-challenge-progress>
    <h3 class="celebration-objective">
      {{ challenge.individual.status.progress.count }} {{ challenge.objective | translate | async }}
      {{ 'Completed!' | translate | async }}
    </h3>
  }
</div>
