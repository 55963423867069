import { HttpErrorResponse } from '@angular/common/http';
import { UserApi, UserEventApi } from '@kapi';
import { AuthenticationApi } from '@kauth';
import { AuthDataService, DataStoreService } from '@kservice';
import { AccountDeletionStatus, DeletionRequestStatus, HttpStatusCode, Product, UserEvent } from '@ktypes/enums';
import { AuthData, Credentials, DataStatus, JsonObject, Status, StatusMessage, User } from '@ktypes/models';
import { BehaviorSubject, Observable, Subject, firstValueFrom, switchMap } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';

export class BaseUserBloc {
  constructor(
    private _authDataService: AuthDataService,
    private _authenticationApi: AuthenticationApi,
    private _dataStoreService: DataStoreService,
    private _userApiService: UserApi,
    private _userEventsApi: UserEventApi
  ) {}

  private _deleteUserStatus = new BehaviorSubject<AccountDeletionStatus>(AccountDeletionStatus.noStatus);
  private _deleteUserRequestStatus = new BehaviorSubject<DeletionRequestStatus>(DeletionRequestStatus.noStatus);
  private _passwordChangeError = new Subject();
  private _signUpStatus = new BehaviorSubject<DataStatus<User>>(null);

  private _emailChangeError = new Subject<DataStatus<any>>();
  private _emailChangeAccessToken = new Subject<DataStatus<string>>();
  private _resendNewEmailStatus = new Subject<DataStatus<any>>();

  private _fetchingUser$ = new BehaviorSubject<DataStatus<boolean>>(null);

  get signUpStatus(): DataStatus<User> {
    return this._signUpStatus.value;
  }

  get signUpStatus$(): Observable<DataStatus<User>> {
    return this._signUpStatus.asObservable();
  }

  get deleteUserStatus$(): Observable<AccountDeletionStatus> {
    return this._deleteUserStatus.asObservable();
  }

  get deleteUserRequestStatus$(): Observable<DeletionRequestStatus> {
    return this._deleteUserRequestStatus.asObservable();
  }

  get passwordChangeError$(): Observable<any> {
    return this._passwordChangeError.asObservable();
  }

  get emailChangeError$(): Observable<DataStatus<any>> {
    return this._emailChangeError.asObservable();
  }

  get emailChangeAccessToken$(): Observable<DataStatus<string>> {
    return this._emailChangeAccessToken.asObservable();
  }

  get resendNewEmailStatus$(): Observable<DataStatus<any>> {
    return this._resendNewEmailStatus.asObservable();
  }

  get fetchingUser$() {
    return this._fetchingUser$.asObservable();
  }

  registerUser(email: string, confirmationCode: string): Promise<DataStatus<User>> | void {
    if (!email || !confirmationCode) {
      console.warn('Missing email or confirmation code, aborting register user');
      return;
    }

    return this._userApiService
      .register(email, confirmationCode)
      .then((userStatus) => {
        if (userStatus?.status === Status.done && userStatus?.data) {
          // ensure unconfirmedEmail is erased
          userStatus.data.unconfirmedEmail = null;
          // update user
          this._dataStoreService.setUser(userStatus.data);
        }
        return userStatus;
      })
      .catch((error: HttpErrorResponse) => {
        console.warn('Error registering user', error);
        return error;
      }) as Promise<DataStatus<User>>;
  }

  signUp(email: string): Promise<void> | void {
    if (this.signUpStatus?.status === Status.starting) {
      // don't do anything if the status is in process (starting)
      return Promise.resolve();
    }

    // Only allow one sign up per session. This will happen if the user
    // completes the onboarding flow, then presses the browser Back button
    // to re-enter the flow.
    if (this.signUpStatus?.status === Status.done && this.signUpStatus.data?.id) {
      this._signUpStatus.next(
        new DataStatus(
          Status.error,
          new StatusMessage(Status.error, 'Already signed up this session. Refresh to try again.'),
          null
        )
      );
      return Promise.resolve();
    }

    if (!email) {
      this._signUpStatus.next(
        new DataStatus(Status.error, new StatusMessage(Status.error, 'Missing email. Please try again.'), null)
      );
      return Promise.resolve();
    }

    // Update unconfirmed email
    this._dataStoreService.setUser({ unconfirmedEmail: email });

    this._signUpStatus.next(new DataStatus(Status.starting, new StatusMessage(Status.starting, ''), null));
    return this._userApiService
      .signUp(this._dataStoreService.user.id, email)
      .then((response) => {
        this._signUpStatus.next(
          new DataStatus(
            response?.status,
            response?.message,
            new User().deserialize({ user: { unconfirmedEmail: email } })
          )
        );
        this._dataStoreService.setUser({ unconfirmedEmail: email });
      })
      .catch((error: HttpErrorResponse) => {
        console.warn('Error trying to signup', error);
        return error;
      }) as Promise<void>;
  }

  hasSetPassword(): Promise<DataStatus<boolean> | void> | void {
    return this._userApiService.hasSetPassword().catch((error: HttpErrorResponse) => {
      console.warn('Error checking if password is set', error);
      return error;
    }) as Promise<DataStatus<boolean> | void>;
  }

  setPassword(password: string): Promise<DataStatus<boolean> | void> | void {
    if (!password) {
      console.warn('Must supply password in order to set the password');
      return Promise.resolve();
    }
    const credentials = new Credentials().deserialize({ password });

    return this._userApiService.setPassword(credentials).catch((error: HttpErrorResponse) => {
      console.warn('Error setting password', error);
      return error;
    }) as Promise<DataStatus<boolean> | void>;
  }

  refreshTokenAndUser$(): Observable<DataStatus<AuthData>> {
    if (this._dataStoreService?.authData?.refreshToken && this._dataStoreService?.authData?.refreshToken !== 'unset') {
      this._dataStoreService.setAuthStatus(new DataStatus<AuthData>(Status.starting, null, null));
      return this._authenticationApi.refreshToken(this._dataStoreService?.authData).pipe(
        withLatestFrom(this._dataStoreService.user$),
        switchMap(([authDataStatus, existingUser]) => {
          const combinedUser =
            existingUser?.id === authDataStatus.data?.user?.id
              ? existingUser.combine(authDataStatus.data?.user)
              : authDataStatus.data?.user;
          return this._userApiService.getUser$(authDataStatus.data?.user?.id, authDataStatus?.data?.token).pipe(
            map((user) => {
              if (authDataStatus?.status === Status.done && authDataStatus?.data != null && user) {
                authDataStatus.data.user = combinedUser?.combine(user);
              }
              this._dataStoreService.setAuthData(authDataStatus?.data);
              this._dataStoreService.setAuthStatus(authDataStatus);
              return authDataStatus;
            })
          );
        })
      );
    } else {
      console.warn(
        `Can't refresh token - refreshToken is ${
          this._dataStoreService?.authData?.refreshToken === 'unset' ? 'unset' : 'not defined'
        }.`
      );
    }
    return null;
  }

  refreshUserFromToken(reject?: () => void): void {
    firstValueFrom(this.refreshTokenAndUser$())
      .then((authDataStatus) => {
        // only need to handle non-done case;
        // authStatus and authData are stored in refreshTokenAndUser$
        if (authDataStatus?.status !== Status.done) {
          this._dataStoreService.setAuthStatus(
            new DataStatus<AuthData>(
              Status.error,
              new StatusMessage(HttpStatusCode.BAD_REQUEST, 'There was a problem refreshing user information'),
              null
            )
          );
          this._authDataService.updateToken(null);
        }
      })
      .catch((error) => {
        console.warn('Error refreshing user from token:', error);
        this._dataStoreService.setAuthStatus(
          new DataStatus<AuthData>(
            Status.error,
            new StatusMessage(HttpStatusCode.BAD_REQUEST, 'Caught a problem refreshing user information'),
            null
          )
        );
        this._authDataService.updateToken(null);
        reject?.();
      });
  }

  getUser(userId: string): void {
    // NOTE: Updates DataStore User on success
    if (!userId) {
      console.warn('null/undefined userId; cannot get user');
      this._fetchingUser$.next(new DataStatus<boolean>(Status.error, null, false));
      return;
    }
    if (this._fetchingUser$.getValue()?.status === Status.starting) {
      // fetch already in progress, not starting another
      console.warn('user fetch already in progress, not starting another');
      return;
    }
    this._fetchingUser$.next(new DataStatus<boolean>(Status.starting, null, true));
    this._userApiService
      .getUser(userId)
      .then((user) => {
        if (user) {
          this._fetchingUser$.next(new DataStatus<boolean>(Status.done, null, false));
          this._dataStoreService.setUser(user);
        } else {
          this._fetchingUser$.next(new DataStatus<boolean>(Status.error, null, false));
          this._dataStoreService.setUser({ error: 'could not update user information' });
          console.warn('There was a problem trying to get the user data via UserBloc');
        }
      })
      .catch((error) => {
        console.warn('There was an error trying to get the user data via UserBloc', error);
        this._fetchingUser$.next(new DataStatus<boolean>(Status.error, null, false));
      });
  }

  updateUserNickname(nickname: string): void {
    this._userApiService.updateUserNickname(nickname).then((updateResponse) => {
      if (updateResponse && updateResponse.status === Status.done) {
        const authData = this._dataStoreService.authData;
        authData.user.nickname = updateResponse.data?.nickname;
        this._dataStoreService.setAuthData(authData);
        this._handleAuthDataResponse(
          new DataStatus<AuthData>(
            Status.done,
            new StatusMessage(HttpStatusCode.OK, ''),
            this._dataStoreService.authData
          )
        );
      }
      if (updateResponse && updateResponse.status === Status.error) {
        this._setPasswordChangeError(updateResponse);
      }
    });
  }

  updateUserEmail(email: string, password: string): void {
    this._userApiService.updateUserEmail(email, password).then((response) => {
      if (response instanceof DataStatus) {
        this._emailChangeAccessToken.next(response);
      }
    });
  }

  confirmUserEmail(accessToken: string, code: string): void {
    this._userApiService.confirmEmail(accessToken, code).then((updateResponse) => {
      if (updateResponse && updateResponse.status === Status.done) {
        const authData = this._dataStoreService.authData;
        authData.user.nickname = updateResponse.data?.nickname;
        authData.user.email = updateResponse.data?.email;
        this._dataStoreService.setAuthData(authData);
        this._handleAuthDataResponse(
          new DataStatus<AuthData>(
            Status.done,
            new StatusMessage(HttpStatusCode.OK, ''),
            this._dataStoreService.authData
          )
        );
      }
      if (updateResponse && updateResponse.status === Status.error) {
        this._setEmailChangeError(updateResponse);
      }
    });
  }

  resendNewEmailConfirmation(email: string, accessToken: string): void {
    this._userApiService.resendNewEmailConfirmation(email, accessToken).then((response) => {
      this._resendNewEmailStatus.next(response);
    });
  }

  deleteUser(): void {
    this._deleteUserStatus.next(AccountDeletionStatus.deleteProcessing);
    let accountDeletionStatus: AccountDeletionStatus;
    this._userApiService.deleteUser().then((deleteUserResponse) => {
      switch (deleteUserResponse.message?.code) {
        case HttpStatusCode.OK:
          accountDeletionStatus = AccountDeletionStatus.accountDeleted;
          break;
        case HttpStatusCode.UNAUTHORIZED:
          accountDeletionStatus = AccountDeletionStatus.tokenExpired;
          break;
        case HttpStatusCode.NOT_FOUND:
          accountDeletionStatus = AccountDeletionStatus.userNotFound;
          break;
        case HttpStatusCode.INTERNAL_SERVER_ERROR:
        default:
          accountDeletionStatus = AccountDeletionStatus.hasErrored;
          break;
      }
      this._deleteUserStatus.next(accountDeletionStatus);
    });
  }

  requestDeleteUser(): void {
    this._deleteUserRequestStatus.next(DeletionRequestStatus.requestProcessing);
    let requestStatus: DeletionRequestStatus;
    this._userApiService.requestDeleteUser().then((deleteUserResponse) => {
      switch (deleteUserResponse.message?.code) {
        case HttpStatusCode.OK:
          requestStatus = DeletionRequestStatus.deletionRequested;
          break;
        case HttpStatusCode.UNAUTHORIZED:
        case HttpStatusCode.INTERNAL_SERVER_ERROR:
        default:
          requestStatus = DeletionRequestStatus.hasErrored;
          break;
      }
      this._deleteUserRequestStatus.next(requestStatus);
    });
  }

  switchApplication(product: Product) {
    const meta: JsonObject<Product> = {
      productRequested: product,
    };
    void this._userEventsApi.postUserEvent(UserEvent.app_switch_requested, meta);
  }

  navigatedFirstTimeToApp(product: Product) {
    const meta: JsonObject<Product> = {
      productRequested: product,
    };
    void this._userEventsApi.postUserEvent(UserEvent.first_url_app_switch, meta);
  }

  private _setPasswordChangeError(error: DataStatus<User>) {
    this._passwordChangeError.next(error);
  }

  private _setEmailChangeError(error: DataStatus<User>) {
    this._emailChangeError.next(error);
  }

  private _handleAuthDataResponse(authDataStatus: DataStatus<AuthData>) {
    // If the authentication succeeded, store the credentials
    if (authDataStatus.status === Status.done && authDataStatus.data != null) {
      this._authDataService.updateToken(new AuthData().deserialize(authDataStatus.data));
    }

    // Add the status to the stream
    this._dataStoreService.setAuthStatus(
      new DataStatus(authDataStatus.status, authDataStatus.message, new AuthData().deserialize(authDataStatus.data))
    );
  }
}
