import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApi, RequestType } from '@kapi';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService, OnboardingUtilities } from '@kservice';
import { HttpStatusCode } from '@ktypes/enums';
import { AuthData, Credentials, DataStatus, JsonObject, Status, StatusMessage } from '@ktypes/models';
import { BehaviorSubject, Observable, firstValueFrom, of, take } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationApi extends BaseApi {
  constructor(
    http: HttpClient,
    dataStoreService: DataStoreService,
    private onboardingUtilities: OnboardingUtilities,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(http, dataStoreService, _environmentVariablesService);
  }

  login(credentials: Credentials): Promise<DataStatus<AuthData>> {
    const url = this.buildUrl('/auth/login');
    const requestBody = {
      email: credentials.email,
      password: credentials.password,
    };
    const request$ = this.performRequest<AuthData>(RequestType.POST, url, { requestBody }).pipe(
      map((result) => {
        if (result.body) {
          this.onboardingUtilities.cleanupOnboardingStorage();
          return new DataStatus<AuthData>(
            Status.done,
            new StatusMessage(HttpStatusCode.OK, 'OK'),
            new AuthData().deserialize(result.body)
          );
        }
        const newAuthData = new AuthData();
        newAuthData.error = 'Valid response with no login information';
        return new DataStatus<AuthData>(
          Status.error,
          new StatusMessage(HttpStatusCode.INTERNAL_SERVER_ERROR, newAuthData.error),
          newAuthData
        );
      }),
      catchError((error) => {
        console.warn('Failed logging in: ', error);
        return of(null);
      })
    );
    return firstValueFrom(request$).catch((error: HttpErrorResponse) => {
      console.warn('Error logging in: ', error);
      const onboardingAuthentication = (error?.error as JsonObject)?.onboardingAuthentication as AuthData;
      const data = onboardingAuthentication ? new AuthData().deserialize(onboardingAuthentication) : null;

      return new DataStatus<AuthData>(
        Status.error,
        new StatusMessage(
          error?.status,
          (error?.error as JsonObject)?.explanation ||
            (error?.error as JsonObject)?.message ||
            'There was a problem logging in'
        ),
        data
      );
    });
  }

  refreshToken(authenticationData?: AuthData): Observable<DataStatus<AuthData>> {
    const authData = authenticationData || this.dataStoreService.authData;
    const { userId, token } = authData;
    const url = this.buildUrl('/auth/token', false, { userId, token, queryParams: {} });
    const requestBody = {
      token: authData.token,
      refreshToken: authData.refreshToken,
    };
    return this.performRequest<AuthData>(RequestType.PUT, url, { requestBody }).pipe(
      take(1), // ensure only a single response is sent
      map((response: HttpResponse<AuthData>): DataStatus<AuthData> => {
        const tokenData = new AuthData().deserialize(response?.body);
        if (tokenData) {
          return new DataStatus<AuthData>(Status.done, new StatusMessage(200, 'OK'), tokenData);
        }
        return null;
      }),
      catchError((error: string) => {
        console.warn('Failed refreshing token: ', error);
        return new BehaviorSubject<DataStatus<AuthData>>(new DataStatus<AuthData>(Status.error));
      })
    );
  }

  revokeToken(tokenToDelete: string): Observable<DataStatus<boolean>> {
    const url = this.buildUrl(`/auth/token/${tokenToDelete}`);
    return this.performRequest<boolean>(RequestType.DELETE, url).pipe(
      map(processRevokeToken),
      catchError(handleObservableError('Failed revoking token: '))
    );

    function processRevokeToken(response: HttpResponse<boolean>) {
      const status = response?.ok ? Status.done : Status.error;
      const statusMessage = response?.ok
        ? new StatusMessage(HttpStatusCode.OK, 'Token Revoked')
        : new StatusMessage(HttpStatusCode.BAD_REQUEST, 'Error revoking token');
      return new DataStatus<boolean>(status, statusMessage, response?.ok);
    }
  }

  requestPasswordReset(email: string): Promise<DataStatus<AuthData>> {
    const credentials = new Credentials(email);
    const url = this.buildUrl('/auth/reset/request');
    const requestBody = {
      email: credentials.email,
    };
    const dataStatus$ = this.performRequest<AuthData>(RequestType.POST, url, { requestBody }).pipe(
      map((response: HttpResponse<AuthData>): DataStatus<AuthData> => {
        if (response?.ok) {
          return new DataStatus<AuthData>(
            Status.done,
            new StatusMessage(200, 'OK'),
            new AuthData().deserialize(response.body)
          );
        }
        const newAuthData = new AuthData();
        newAuthData.error = 'Valid response with no email information';
        return new DataStatus<AuthData>(
          Status.error,
          new StatusMessage(HttpStatusCode.INTERNAL_SERVER_ERROR, newAuthData.error),
          newAuthData
        );
      }),
      catchError((error: HttpErrorResponse) => {
        const onboardingAuthentication = (error?.error as JsonObject)?.onboardingAuthentication as AuthData;
        const data = onboardingAuthentication ? new AuthData().deserialize(onboardingAuthentication) : null;
        console.warn('Failed requesting password reset: ', error);
        return of(
          new DataStatus<AuthData>(
            Status.error,
            new StatusMessage(
              error?.status,
              (error?.error as JsonObject)?.explanation ||
                (error?.error as JsonObject)?.message ||
                'There was a problem logging in'
            ),
            data
          )
        );
      })
    );
    return firstValueFrom(dataStatus$).catch((error): null => {
      console.warn('Error requesting password reset: ', error);
      return null;
    });
  }

  changePassword(email: string, password: string, code: string): Promise<DataStatus<boolean>> {
    const credentials = new Credentials().deserialize({ email, password, code });
    const url = this.buildUrl('/auth/reset');
    const requestBody = {
      email: credentials.email,
      password: credentials.password,
      code: credentials.code,
    };
    const dataStatus$ = this.performRequest<boolean>(RequestType.POST, url, { requestBody }).pipe(
      map((response: HttpResponse<boolean>): DataStatus<boolean> => {
        return new DataStatus<boolean>(
          response?.ok ? Status.done : Status.error,
          response?.ok
            ? new StatusMessage(200, 'OK')
            : new StatusMessage(HttpStatusCode.INTERNAL_SERVER_ERROR, 'Valid response with no code information'),
          response?.ok
        );
      }),
      catchError(handleObservableError('Error resetting password'))
    );

    return firstValueFrom(dataStatus$).catch(handlePromiseError('Error changing password'));
  }

  checkForExistingAccount(userId?: string, eligibilityId?: string): Promise<DataStatus<boolean>> {
    const queryParams: JsonObject = {};
    if (userId) {
      queryParams['userId'] = userId;
    }
    if (eligibilityId) {
      queryParams['eligibilityId'] = eligibilityId;
    }
    const request$ = this.performRequest<boolean>(
      RequestType.GET,
      this.buildUrl('/user/check-existing', false, queryParams)
    ).pipe(
      map((response: HttpResponse<any>) =>
        response?.ok
          ? new DataStatus<boolean>(Status.done, null, true)
          : new DataStatus<boolean>(Status.done, null, false)
      ),
      catchError(handleObservableError('Failed getting existing user status'))
    );
    return firstValueFrom(request$).catch(handlePromiseError('Error getting existing user status'));
  }
}

function handleObservableError(errorMessage: string) {
  return (error: HttpErrorResponse) => {
    console.warn(errorMessage, error);
    return of(
      new DataStatus<boolean>(Status.error, new StatusMessage(error.status, error?.message ?? errorMessage), null)
    );
  };
}

function handlePromiseError(errorMessage: string) {
  return (error: HttpErrorResponse) => {
    console.warn(errorMessage, error);
    return new DataStatus<boolean>(Status.error, new StatusMessage(error.status, error?.message ?? errorMessage), null);
  };
}
