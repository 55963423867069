import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { AuthenticationBloc } from '@kp/auth/authentication.bloc';
import { ValidationApi } from '@kp/core/api/validation.api';
import { UserBloc } from '@kp/user/user.bloc';
import { AuthDataService, BrowserStorage, DataStoreService, OnboardingUtilities } from '@kservice';
import { Product, UserType } from '@ktypes/enums';
import { DataStatus, Group, LegalDocument, OnboardingData, Status, StatusMessage, User } from '@ktypes/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GroupValidationBloc {
  onboardingData: OnboardingData;
  constructor(
    private _authenticationBloc: AuthenticationBloc,
    private _authDataService: AuthDataService,
    private _browserStorage: BrowserStorage,
    private _dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService,
    private _onboardingUtilities: OnboardingUtilities,
    private _validationApi: ValidationApi,
    private _userBloc: UserBloc
  ) {}

  private _groupValidationDataStatusSubject = new BehaviorSubject<DataStatus<Group>>(null);

  get groupValidationDataStatus(): DataStatus<Group> {
    return this._groupValidationDataStatusSubject.getValue();
  }

  get groupValidationDataStatus$(): Observable<DataStatus<Group>> {
    return this._groupValidationDataStatusSubject.asObservable();
  }

  refreshGroup(groupCode = ''): void {
    this._validationApi
      .validateGroupCode(groupCode)
      .then((fetchedGroupCode) => {
        if (fetchedGroupCode && fetchedGroupCode.data) {
          this._dataStoreService.setUser({
            ...this._dataStoreService.authData.user,
            group: new Group().deserialize(fetchedGroupCode.data),
            theme: fetchedGroupCode.data.theme,
          } as Partial<User>);
          this._authDataService.updateToken(this._dataStoreService.authData);
        }
        this._groupValidationDataStatusSubject.next(fetchedGroupCode);
      })
      .catch((error: HttpErrorResponse) => {
        console.warn(error);
        return new DataStatus(Status.error, new StatusMessage(error.status, error.statusText), null);
      });
  }

  startValidatingGroupCode(passedGroupCode = '', source?: string, onboardingPage = '/welcome'): void {
    let groupCode = passedGroupCode;
    if (!groupCode) {
      groupCode = ((this._browserStorage.getObject('onboarding') as OnboardingData)?.groupCode as string) || '';
    }
    this._groupValidationDataStatusSubject.next(
      new DataStatus(Status.starting, new StatusMessage(Status.starting, ''), new Group().deserialize({ groupCode }))
    );
    this._validationApi
      .validateGroupCode(groupCode)
      .then((fetchedGroupValidationStatus) => {
        const groupWasFound: boolean =
          fetchedGroupValidationStatus?.status === Status.done && !!fetchedGroupValidationStatus?.data?.id;
        const fetchedGroup: Group = new Group().deserialize(fetchedGroupValidationStatus?.data);
        const storedGroupCode = this._authenticationBloc?.storedGroupCode.toLowerCase();
        const hasUserAndGroup = this._dataStoreService.authData?.user && fetchedGroup && groupWasFound;
        const groupCodeChanged = this._dataStoreService.authData?.user?.group?.groupCode !== fetchedGroup?.groupCode;
        // maintain user only if it is the same groupCode
        if (hasUserAndGroup && !groupCodeChanged) {
          this._dataStoreService.setUser({
            ...this._dataStoreService.user,
            group: fetchedGroup,
          });
        }

        if (
          fetchedGroupValidationStatus.status === Status.done &&
          this._authenticationBloc &&
          (!this._authenticationBloc.storedOnboarding || storedGroupCode !== groupCode.toLowerCase())
        ) {
          this.onboardingData = {
            groupCode,
            latestPage: onboardingPage,
            groupId: fetchedGroup?.id,
          };
          this._onboardingUtilities.saveOnboardingData(this.onboardingData);
          // create a user if there is a groupCode but not a user, and not on Resourceful
          if (
            (storedGroupCode || this._dataStoreService?.authData?.user?.group?.groupCode) &&
            (!hasUserAndGroup || groupCodeChanged) &&
            this._environmentVariablesService.product !== Product.resourceful &&
            source !== 'payment'
          ) {
            const userType = this._dataStoreService?.authData?.user?.type;
            this._authenticationBloc.clearAuthData();
            // this check is necessary so Resourceful doesn't create onboarding users when group code switching
            if (userType !== UserType.pulse) {
              this._userBloc.createOnboardingUser(fetchedGroup, null);
            }
          }
        }
        this._groupValidationDataStatusSubject.next(fetchedGroupValidationStatus);
      })
      .catch((error: HttpErrorResponse) => {
        console.warn(error);
        return new DataStatus(Status.error, new StatusMessage(error.status, error.statusText), null);
      });
  }

  checkForGroup(): { groupCode: string; groupId: string; error: DataStatus<LegalDocument> } {
    const groupData = this.groupValidationDataStatus?.data;
    if (!groupData) {
      return {
        groupCode: null,
        groupId: null,
        error: new DataStatus<LegalDocument>(
          Status.error,
          new StatusMessage(Status.error, 'No group exists. Please refresh and try again.'),
          null
        ),
      };
    }

    const groupCode = this.groupValidationDataStatus?.data?.groupCode;
    const groupId = this.groupValidationDataStatus?.data?.id;

    return { groupCode, groupId, error: null };
  }
}
