import { Inject, Injectable } from '@angular/core';
import { FirebaseApp } from '@angular/fire/app';
import { Messaging } from '@angular/fire/messaging';
import { EnvironmentVariablesService } from '@kenv';
import { NotificationsApi } from '@kp/notifications/notifications.api';
import { Constants } from '@kp/shared/constants.service';
import { WINDOW } from '@kservice';
import { CardEventType } from '@ktypes/enums';
import { DataStatus, JsonObject, NotificationDevice, NotificationInfo, Status } from '@ktypes/models';
import { DateTimeUtil, DaysWeekAbbreviation } from '@kutil';
import { getMessaging, getToken } from 'firebase/messaging';
import { BehaviorSubject, Observable, Subject, combineLatest, from, of, share } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export enum NotificationPermissionStatus {
  enabled = 'enabled',
  notNow = 'notNow',
}

export enum NotificationPermissionState {
  default = 'default',
  granted = 'granted',
  denied = 'denied',
}

@Injectable({
  providedIn: 'root',
})
export class NotificationsBloc {
  constructor(
    private _environmentVariablesService: EnvironmentVariablesService,
    private _firebaseApp: FirebaseApp,
    private _notificationsApi: NotificationsApi,
    @Inject(WINDOW) private _window: Window
  ) {
    try {
      this._messaging = getMessaging(this._firebaseApp);
      if (this._messaging && _window.Notification?.permission === NotificationPermissionState.granted) {
        this.fcmToken$ = combineLatest([
          this.fcmToken$?.pipe(map((fcmToken) => fcmToken?.[0] ?? '')) ?? of(''),
          from(
            _window.navigator.serviceWorker
              .register('/firebase-messaging-sw.js' /* , { type: 'module' , scope: '__'  } */)
              .then((serviceWorkerRegistration) =>
                getToken(this._messaging, {
                  serviceWorkerRegistration,
                  vapidKey: this._environmentVariablesService.config.vapidKey as string,
                })
              )
          ),
        ]).pipe(
          tap(([previousToken, updatedToken]) => {
            if (updatedToken && previousToken !== updatedToken) {
              this._storeDevice(updatedToken);
            }
          }),
          share()
        );
      }

      this.fcmToken$?.subscribe(([previousToken, updatedToken]) => {
        // send updated token to server if it changes
        if (updatedToken && previousToken !== updatedToken) {
          this._storeDevice(updatedToken);
        }
      });
    } catch (error) {
      console.warn('Error trying to instantiate Firebase Messaging', error);
    }

    // Handle incoming messages. Called when:
    // - a message is received while the app has focus
    // - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
    /*const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      console.log('In App Message received. ', payload);
    });*/
  }

  fcmToken$: Observable<[string, string]>;

  private readonly _messaging: Messaging;
  private _notificationSubject = new BehaviorSubject<DataStatus<NotificationInfo[]>>(null);
  private _updatedNotification$ = new Subject<NotificationInfo>();
  private _devicesSubject = new BehaviorSubject<DataStatus<NotificationDevice[]>>(null);

  get notificationSubject$(): Observable<DataStatus<NotificationInfo[]>> {
    return this._notificationSubject.asObservable();
  }

  get updatedNotification$(): Observable<NotificationInfo> {
    return this._updatedNotification$.asObservable();
  }

  get devicesSubject$(): Observable<DataStatus<NotificationDevice[]>> {
    return this._devicesSubject.asObservable();
  }

  isSupported(): boolean {
    return 'PushManager' in this._window && 'Notification' in this._window;
  }

  refresh(): void {
    this._notificationSubject.next(new DataStatus<NotificationInfo[]>(Status.starting, null, null));
    this._notificationsApi.getNotifications().then((notificationInfo) => {
      this._notificationSubject.next(
        new DataStatus<NotificationInfo[]>(notificationInfo ? Status.done : Status.error, null, notificationInfo)
      );
    });
  }

  updateNotification(notificationToUpdate: NotificationInfo): void {
    this._notificationsApi.updateNotification(notificationToUpdate).then((notification) => {
      this._updatedNotification$.next(notification);
      this.refresh();
    });
  }

  createNotification(notificationToCreate: NotificationInfo): void {
    this._notificationsApi.createNotification(notificationToCreate).then((notification) => {
      this._updatedNotification$.next(notification);
      this.refresh();
    });
  }

  deleteNotification(notificationToDelete: NotificationInfo): void {
    this._notificationsApi.deleteNotification(notificationToDelete).then(() => {
      this.refresh();
    });
  }

  handleCardNotificationUpdates(cardEventType: CardEventType, notification: NotificationInfo): void {
    switch (cardEventType) {
      case CardEventType.REMINDER_EDITED:
        if (notification?.id) {
          this.updateNotification(notification);
        } else {
          this.createNotification(notification);
        }
        break;
      case CardEventType.REMINDER_DELETED:
        if (!notification?.id) {
          return;
        }
        this.deleteNotification(notification);
        break;
    }
  }

  async requestPermission(): Promise<NotificationPermission> {
    try {
      // if permission is already granted, don't request again
      if (this._window.Notification?.permission === NotificationPermissionState.granted) {
        this.registerDevice(this._window.Notification?.permission);
        return this._window.Notification?.permission;
      }
      return this._window.Notification.requestPermission()?.then(
        (permission: NotificationPermission) => {
          if (permission === NotificationPermissionState.granted) {
            this.registerDevice(permission);
          } else {
            console.warn('NotificationBloc: Notification requestPermission not granted');
          }
          return permission;
        },
        (error) => {
          console.warn('Unable to request Firebase token: ', error);
          return NotificationPermissionState.denied;
        }
      );
    } catch (error) {
      console.warn('Error trying to instantiate Firebase Messaging', error);
      return this._window.Notification?.permission ?? NotificationPermissionState.denied;
    }
  }

  registerDevice(permission: NotificationPermission): void {
    (this.fcmToken$?.pipe(map((fcmToken) => fcmToken?.[0] ?? '')) ?? of('')).subscribe((previousToken) => {
      this._window.navigator.serviceWorker
        .register('/firebase-messaging-sw.js' /* , { type: 'module' , scope: '__'  } */)
        .then((serviceWorkerRegistration) => {
          getToken(this._messaging, {
            vapidKey: this._environmentVariablesService.config.vapidKey as string,
            serviceWorkerRegistration: serviceWorkerRegistration,
          }).then((updatedToken) => {
            if (updatedToken && updatedToken !== previousToken) {
              this._storeDevice(updatedToken);
            }
          });
        });
    });
  }

  getDevices(): void {
    this._devicesSubject.next(new DataStatus<NotificationDevice[]>(Status.starting, null, null));
    this._notificationsApi.getDevices().then((devices) => {
      this._devicesSubject.next(new DataStatus<NotificationDevice[]>(Status.done, null, devices));
    });
  }

  deleteWebDevices(deviceList: NotificationDevice[]): void {
    for (const device of deviceList) {
      if (device.platformName === Constants.platformName) {
        void this._notificationsApi.deleteDevice(device.id);
      }
    }
    const updatedList = deviceList.filter((device) => device.platformName !== Constants.platformName);
    this._devicesSubject.next(new DataStatus<NotificationDevice[]>(Status.done, null, updatedList));
  }

  getSelectedDays(days: DaysWeekAbbreviation[]): string {
    if (DateTimeUtil.everyDay.every((day) => days.includes(day))) {
      return 'Every Day';
    } else if (days?.length === 5 && DateTimeUtil.weekdays.every((day) => days.includes(day))) {
      return 'Weekdays';
    } else if (days?.length === 2 && DateTimeUtil.weekends.every((day) => days.includes(day))) {
      return 'Weekends';
    } else {
      if (days.length === 1) {
        return days[0];
      } else {
        const capitalized = DateTimeUtil.everyDay.filter((day) => days.includes(day)).map((day) => day);
        return capitalized.join(', ');
      }
    }
  }

  private _storeDevice(registrationToken: string) {
    // store it on your database for each user
    const json: JsonObject = {};
    json['notificationPlatform'] = Constants.platformName;
    json['registrationToken'] = registrationToken;
    void this._notificationsApi.createDevice(json);
  }
}
