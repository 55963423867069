import { InjectionToken } from '@angular/core';
import { AnalyticsPageName, PageMappingData } from '@kanalytics';

export const PAGE_MAPPING = new InjectionToken<PageMappingData>('PageMapping');

export const PAGE_MAPPING_DATA: PageMappingData = {
  pages: [
    { pageName: AnalyticsPageName.accountDeletion, regex: /^\/deletion$/ },
    {
      pageName: AnalyticsPageName.accountDeletionConfirmation,
      regex: /^\/deletion-confirmation$/,
      meta: { focusTitle: 'Request Account Deletion Confirmation' },
    },
    { pageName: AnalyticsPageName.challengeDetail, regex: /^\/challenges\/.*/, params: ['challengeId'] },
    { pageName: AnalyticsPageName.challenges, regex: /^\/challenges.*/, meta: { focusElementSelector: '.title' } },
    { pageName: AnalyticsPageName.changeEmail, regex: /^\/settings\/change-email$/ },
    { pageName: AnalyticsPageName.changeNickname, regex: /^\/settings\/change-nickname.*/ },
    { pageName: AnalyticsPageName.confirmEmail, regex: /^\/welcome\/signup\/verification\/email.*/ },
    { pageName: AnalyticsPageName.confirmEmailResendCode, regex: /^\/welcome\/signup\/verification\/no-code.*/ },
    { pageName: AnalyticsPageName.confirmNewEmail, regex: /^\/settings\/change-email\/verification.*/ },
    {
      pageName: AnalyticsPageName.confirmNewEmailResendCode,
      regex: /^\/settings\/change-email\/verification\/no-code.*/,
    },
    {
      pageName: AnalyticsPageName.compare,
      regex: /^\/me\/life\/compare.*/,
      meta: { type: 'life', focusElementSelector: '.title', focusTitle: 'Our Best Selves' },
    },
    {
      pageName: AnalyticsPageName.compare,
      regex: /^\/me\/work\/compare.*/,
      meta: { type: 'work', focusElementSelector: '.title', focusTitle: 'Our Best Selves' },
    },
    { pageName: AnalyticsPageName.dialogue, regex: /^\/dialogue(\?|$).*/ },
    { pageName: AnalyticsPageName.discover, regex: /^\/discover.*/ },
    { pageName: AnalyticsPageName.eligibility, regex: /^\/welcome\/eligibility\/email.*/, meta: { type: 'email' } },
    {
      pageName: AnalyticsPageName.eligibility,
      regex: /^\/welcome\/eligibility\/postal_dob_name.*/,
      meta: { type: 'postal_dob_name' },
    },
    { pageName: AnalyticsPageName.eligibility, regex: /^\/welcome\/eligibility.*/ },
    {
      pageName: AnalyticsPageName.eula,
      regex: /^\/eula.*/,
      meta: { focusTitle: 'Acceptable User Policy' },
    },
    { pageName: AnalyticsPageName.feedback, regex: /^\/feedback.*/, meta: { focusElementSelector: '.main-title' } },
    { pageName: AnalyticsPageName.help, regex: /^\/help.*/, meta: { focusElementSelector: '.title' } },
    { pageName: AnalyticsPageName.invite, regex: /^\/invite.*/, meta: { focusElementSelector: '.title' } },
    { pageName: AnalyticsPageName.landing, regex: /^\/welcome$/ },
    { pageName: AnalyticsPageName.legalDocAcceptance, regex: /^\/privacy-full*/ },
    {
      pageName: AnalyticsPageName.loginEmail,
      regex: /^\/login\/email.*/,
      meta: { focusTitle: 'Sign in with email' },
    },
    {
      pageName: AnalyticsPageName.loginResetCode,
      regex: /^\/login\/reset\/code\/resend.*/,
      meta: { focusTitle: 'Resend Password Reset Code?' },
    },
    {
      pageName: AnalyticsPageName.loginResetCode,
      regex: /^\/login\/reset\/code.*/,
      meta: { focusTitle: 'Enter Reset Password Code' },
    },
    {
      pageName: AnalyticsPageName.loginPassword,
      regex: /^\/login\/reset\/password.*/,
      meta: { focusTitle: 'Reset Your Password' },
    },
    { pageName: AnalyticsPageName.login, regex: /^\/login.*/, meta: { focusTitle: 'Sign in' } },
    { pageName: AnalyticsPageName.logout, regex: /^\/logout.*/ },
    { pageName: AnalyticsPageName.meView, regex: /^\/me$/, meta: { focusTitle: 'Me (Profile)' } },
    {
      pageName: AnalyticsPageName.meEdit,
      regex: /^\/me\/edit.*/,
      meta: { focusTitle: 'Edit Profile' },
    },
    {
      pageName: AnalyticsPageName.mobileAppInfo,
      regex: /^\/mobile_app_info.*/,
      meta: { focusElementSelector: '.app-info-topic' },
    },
    { pageName: AnalyticsPageName.mobileAppInterstitial, regex: /^\/welcome\/mobile-app\/interstitial.*/ },
    { pageName: AnalyticsPageName.myPhotos, regex: /^\/me\/photos.*/ },
    { pageName: AnalyticsPageName.privacyPledge, regex: /^\/welcome\/notice.*/ },
    {
      pageName: AnalyticsPageName.purposefulDays,
      regex: /^\/purposeful-days.*/,
      meta: { focusElementSelector: '.title' },
    },
    {
      pageName: AnalyticsPageName.setPassword,
      regex: /^\/welcome\/signup\/password.*/,
      meta: { focusTitle: 'Create a Password' },
    },
    { pageName: AnalyticsPageName.siteMap, regex: /^\/site-map$/, meta: { focusElementSelector: '.site-map-title' } },
    { pageName: AnalyticsPageName.settings, regex: /^\/settings$/, meta: { focusElementSelector: '.title' } },
    {
      pageName: AnalyticsPageName.signup,
      regex: /^\/welcome\/signup(?!\/(email|verification\/email|verification\/no-code)).*/,
      meta: { focusTitle: 'Resend Access Code?' },
    },
    { pageName: AnalyticsPageName.signupEmail, regex: /^\/welcome\/signup\/email.*/ },
    { pageName: AnalyticsPageName.onboarding, regex: /^\/welcome\/error.*/ },
    { pageName: AnalyticsPageName.onboarding, regex: /^\/welcome(?!\/privacy).*/ },
    { pageName: AnalyticsPageName.onboardingDialogue, regex: /^\/dialogue\/onboarding.*/ },
    {
      pageName: AnalyticsPageName.onboardingLegalDocAcceptance,
      regex: /^\/welcome\/privacy.*/,
      meta: { focusTitle: 'Privacy Policy' },
    },
    {
      pageName: AnalyticsPageName.privacy,
      regex: /^\/privacy.*/,
      meta: { focusTitle: 'Privacy Notice' },
    },
    { pageName: AnalyticsPageName.progress, regex: /^\/progress.*/ },
    { pageName: AnalyticsPageName.pulseSurveyIntroScreen, regex: /^\/pulse_survey.*/ },
    { pageName: AnalyticsPageName.questionSet, regex: /^\/question_set.*/ },
    { pageName: AnalyticsPageName.quest, regex: /^\/quest.*/ },
    {
      pageName: AnalyticsPageName.reminders,
      regex: /^\/reminders.*/,
      meta: { focusTitle: 'Notifications' },
    },
    { pageName: AnalyticsPageName.report, regex: /^\/report.*/ },
    { pageName: AnalyticsPageName.resources, regex: /^\/resources.*/ },
    { pageName: AnalyticsPageName.saved, regex: /^\/cards\/saved.*/, meta: { focusElementSelector: '.title' } },
    {
      pageName: AnalyticsPageName.takeAction,
      regex: /^\/cards\/take-action.*/,
      meta: { focusElementSelector: '.title', focusTitle: 'Take Action' },
    },
    { pageName: AnalyticsPageName.today, regex: /^\/today.*/ },
  ],
  dontTrack: ['/login/email', '/login/reset/code', '/login/reset/password', '/logout'],
  dontCache: ['/login'],
};
